const endpoints = {
	createBudgetConcept: '/budgets/createBudgetConcept',
	updateBudgetConcept: '/budgets/updateBudgetConcept',
	getBudgetConcepts: '/budgets/getBudgetConcepts',
	removeBudgetConcept: '/budgets/removeBudgetConcept',
	createBudgetCompanyCategory: '/budgets/createBudgetCompanyCategory',
	updateBudgetCompanyCategory: '/budgets/updateBudgetCompanyCategory',
	getBudgetCompanyCategories: '/budgets/getBudgetCompanyCategories',
	removeBudgetCompanyCategory: '/budgets/removeBudgetCompanyCategory',
	createBudgetCompanyDetail: '/budgets/createBudgetCompanyDetail',
	updateBudgetCompanyDetail: '/budgets/updateBudgetCompanyDetail',
	getBudgetCompanyDetails: '/budgets/getBudgetCompanyDetails',
	removeBudgetCompanyDetail: '/budgets/removeBudgetCompanyDetail',
	updateOrderBudgetDetail: '/budgets/updateOrderBudgetDetail',
}
export {endpoints}