<template>
  <div class="text-center">
    <v-dialog
      v-model="$store.state.preprojects.newPreProjectModal"
      max-width="500"
      scrollable
    >
      <v-card>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
                <v-icon color="white" small class="info-icon mr-2">mdi-account</v-icon>
                <p class="mb-0 font-weight-bold text-primary title">Crear Cliente</p>
              </div>
              <v-btn class="mx-2" fab dark small color="#F4516C" @click="closeModal">
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-form ref="form" v-model="validform" class="mt-6">
              <v-row class="mb-4">
								<v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="newPreProjectData.name"
                    label="Nombre del Proyecto"
                    placeholder="Por favor ingrese nombre al proyecto"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
								<!-- <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="newPreProjectData.description"
                    label="Descripción Breve (Opcional)"
                    placeholder="Por favor ingrese una breve descripción para el preproyecto"
                    outlined
                    required
                    dense
                  ></v-text-field>
                </v-col> -->
							</v-row>

              <v-row align="center">
                <v-col cols="12">
                  <div class="d-flex flex-row justify-end">
                    <v-btn
                      class="mx-2 my-1"
                      color="white"
                      raised
                      @click="closeModal"
                    >
                      <p class="mb-0 text-primary">Cancelar</p>
                    </v-btn>
                    <v-btn
                      class="mx-2 my-1"
                      color="#524D4D"
                      raised
                      dark
                      :loading="loadingData"
                      @click="sendForm"
                    >
                      <p class="mb-0">Confirmar</p>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'
import {createPreProject} from '@/helpers/api/preprojects'

export default {
  name: 'NewPreProjectModal',
  components: { },
  data: () => ({
    validform: true,
		items: [],
		loading: false,
		autocompleteService: null,
		search: '',
    newPreProjectData: {
      client_id: null,
      name: null,
      description: null,
			crm_id: null,
			address: null,
			coordinates: null
    },
    loadingData: false,
    requiredRule: [
      v => !!v || 'Campo requerido'
    ],
    phoneRule: [
			v => !!v || 'Campo requerido',
			v => /^569\d{8}$/.test(v) || 'Debe tener 11 dígitos y empezar con 569',
    ],
    emailRules: [
      v => !!v || 'Email es obligatorio',
      v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
    ],
  }),
  computed: {
		google: gmapApi,
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		}
  },
	watch: {
    search(newSearch) {
      if (newSearch) {
        this.googleSearch(newSearch);
      } else {
        this.items = []; // Limpiar los resultados si no hay búsqueda
      }
    },
	},
  mounted(){
		this.$nextTick(() => {
			this.$gmapApiPromiseLazy().then(() => {
				this.autocompleteService = new this.google.maps.places.AutocompleteService()
			})
			const pre_info = localStorage.getItem('new_preproject_info')
			localStorage.removeItem('new_preproject_info')
			const preproject_info = pre_info ? JSON.parse(pre_info):null
			if(preproject_info){
				this.newPreProjectData = {
					...this.newPreProjectData,
					...preproject_info
				}
			}
		});
  },
  methods: {
    googleSearch(query) {
      const options = {
        input: query,
				componentRestrictions: { country: 'CL' }
      };
      this.autocompleteService.getPlacePredictions(options, (predictions, status) => {
        if (status === this.google.maps.places.PlacesServiceStatus.OK) {
          this.items = predictions.map(prediction => ({
            text: prediction.description,
            value: prediction 
          }));
        } else {
          console.error('Error fetching predictions: ', status);
          this.items = [];
        }
      });
    },
		async getPlaceDetails(placeId) {
			if (typeof this.google !== 'undefined' && this.google.maps) {
				const service = new this.google.maps.places.PlacesService(document.createElement('div'));
				
				service.getDetails({ placeId }, (place, status) => {
					if (status === this.google.maps.places.PlacesServiceStatus.OK) {
						const { geometry } = place;
						this.newPreProjectData.coordinates = {
							lat: geometry.location.lat(),
							lng: geometry.location.lng()
						}
					}
				});
			} else {
				console.error('Google Maps API no está cargada');
			}
		},
    change(value) {
			this.getPlaceDetails(value.value.place_id)
      this.newPreProjectData.address = value.text;
    },
		isNumberKey(event) {
			const charCode = event.which ? event.which : event.keyCode;
			if (charCode < 48 || charCode > 57) {
				event.preventDefault();
			}
		},
    closeModal(){
			this.$store.commit('preprojects/setNewPreProjectModal', false)
      this.newPreProjectData = {
				client_id: null,
				name: null,
				description: null,
				crm_id: null,
				address: null,
				coordinates: null
			}
      this.validform = true
      this.loadingData = false
    },
    async sendForm(){
      if(this.$refs.form.validate()){
        this.loadingData = true
        const preproject = await createPreProject(this.newPreProjectData)
        if(preproject.code === 200){
          const messageData = {
            message: 'Se ha creado correctamente el Pre Proyecto',
            title: 'Pre Proyecto creado',
            type: 'success',
            icon: 'mdi-check-circle',
            color: '#3EBFA3',
          }
					// this.$router.push({ name: 'Conversation', params: { id: crm.conversation.id } })
					this.$router.push({ name: 'PreProject', params: { id: preproject.pre_project.id } })
          this.setMessage(messageData)
          this.closeModal()
        }
        else if(preproject.data){
          preproject.data.forEach(message => {
            const messageData = {
              message,
              title: 'Error',
              type: 'danger',
              icon: 'mdi-close-octagon',
              color: '#F4516C',
            }
            this.setMessage(messageData)
          })
        }
        this.loadingData = false
      }
    },
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    },
  }
};
</script>

<style scoped>
	.profile-avatar{
		cursor: pointer;
		position: relative;
	}
	.logo-text{
		position: absolute;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.7);
		width: 100%;
		color: white;
		font-size: 12px;
		padding-bottom: 10px;
		padding-top: 3px;
	}
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #524D4D;
  }
  .info-icon{
    background-color: #524D4D;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #959595;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
</style>