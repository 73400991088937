<template>
	<div class="bot-container">
		<ChatBox />
		<v-btn
			elevation="2"
			fab
			color="#DD4F40"
			@click="$store.commit('chatbot/setShowChatBox', !$store.state.chatbot.showChatBox)"
		>
			<v-icon color="white">mdi-chat</v-icon>
		</v-btn>
	</div>
</template>

<script>
import ChatBox from './ChatBox'
export default {
	name: 'ChatBot',
	components: {ChatBox},
  data() {
    return {
    };
  },
	mounted(){
	},
  methods: {
  },
};
</script>

<style scoped>
	.bot-container{
		position: absolute;
		bottom: 10px;
		left: 10px;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		z-index: 9999;
	}
</style>