<template>
	<v-menu
		ref="menu"
		v-model="menuDatePicker"
		:close-on-content-click="false"
		:return-value.sync="selectedDate"
		transition="scale-transition"
		offset-y
		min-width="auto"
		eager
	>
		<template v-slot:activator="{ on, attrs }">
			<div
				class="date-viewer"
				v-bind="attrs"
				v-on="on"
			>
				<div v-if="selectedDate" class="font-weight-bold body-2">
					{{selectedDate | toDate}}
				</div>
				<div v-else>
					Seleccionar
				</div>
			</div>
		</template>
		<v-date-picker
			v-model="selectedDate"
			no-title
			scrollable
			:first-day-of-week="1"
			locale="es-cl"
			:events="holidays"
			:picker-date.sync="pickerDate"
			event-color="red lighten-1"
			@input="(date) => $refs.menu.save(date)"
			@change="updateDate"
			class="pb-4"
		/>
	</v-menu>
</template>

<script>
import moment from "moment"
import {getHolidays} from '@/helpers/api/schedule'
import {updateDocumentRevision} from '@/helpers/api/preprojects'

export default {
	name: 'DocumentDateSelector',
	computed: {
		holidays(){
			return this.currentYearHolidays || this.$store.state.general.holidayDays
		},
    preproject() {
      return this.$store.state.preprojects.preProject
    },
		documentInfo(){
			const found = this.preproject.documents.find((document) => {
				return document.document_type_id == this.documentTypeId
			})
			return found
		},
		documentDate(){
			const found = this.preproject.documents.find((document) => {
				return document.document_type_id == this.documentTypeId
			})
			if(found){
				const dateTypeMapping = {
					1: 'request_date',
					2: 'max_date',
					3: 'delivered_date'
				}
				if (this.dateType in dateTypeMapping && found[dateTypeMapping[this.dateType]]) {
					return found[dateTypeMapping[this.dateType]]
				}
			}
			return null
		}
	},
	props: {
		documentTypeId: {
			type: Number,
			required: true
		},
		dateType: {
			type: Number,
			required: true
		},
		loading: {
			type: Function,
			required: true
		}
	},
	watch: {
		currentYear(newValue, oldValue){
			if(newValue != oldValue){
				this.updateHolidays()
			}
		},
		pickerDate(newValue){
			if(newValue){
				this.currentYear = moment(newValue).format('YYYY')
			}
		},
		documentDate(newValue){
			if(newValue){
				this.selectedDate = newValue
			}
		},
	},
	data() {
		return{
			menuDatePicker: false,
			selectedDate: null,
			currentYear: 2024,
			pickerDate: null,
			currentYearHolidays: null,
		}
	},
	mounted(){
		this.selectedDate = this.documentDate
	},
	methods: {
		async updateHolidays(){
			const holidays = await getHolidays({year: this.currentYear})
			if(holidays.code == 200){
				this.currentYearHolidays = holidays.events
			}
		},
		async updateDate(){
			this.loading(true)
			let data = {document_type_id: this.documentTypeId, preproject_id: this.$route.params.id}
			const dateTypeMapping = {
				1: 'request_date',
				2: 'max_date',
				3: 'delivered_date'
			}

			if (this.dateType in dateTypeMapping) {
				data[dateTypeMapping[this.dateType]] = this.selectedDate
			}
			await updateDocumentRevision(data)
			this.$store.dispatch('preprojects/getPreProject',  {preproject_id: this.$route.params.id, showLoader: false})
			this.loading(false)
		}
	}
}
</script>