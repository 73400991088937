<template>
	<v-card class="pa-0">
		<v-card-text class="pa-0" style="overflow:hidden">
			<v-simple-table class="custom-table rounded">
				<thead>
					<tr>
						<th></th>
						<th class="text-center">DOCUMENTO/PLANO</th>
						<th class="text-center">FECHA SOLICITUD</th>
						<th class="text-center">FECHA MÁXIMA DE ENTREGA</th>
						<th class="text-center">FECHA DE ENTREGA</th>
						<th class="text-center">ESTADO</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td :rowspan="unarchive.length + 1">DESARCHIVO MUNICIPAL</td>
					</tr>
					<tr v-for="(un, index) in unarchive" :key="'un'+index" :class="un.loading ? 'loading':''">
						<td>{{un.text ? un.text + ' / ' + un.label:un.label}}</td>
						<td><DocumentDateSelector :documentTypeId="un.id" :dateType="1" :loading="(status) => un.loading = status"/></td>
						<td><DocumentDateSelector :documentTypeId="un.id" :dateType="2" :loading="(status) => un.loading = status"/></td>
						<td><DocumentDateSelector :documentTypeId="un.id" :dateType="3" :loading="(status) => un.loading = status"/></td>
						<td>
							<v-icon v-if="status(un.id)" :color="status(un.id).color" small>{{ status(un.id).icon }}</v-icon>
							<p class="mb-0" v-if="status(un.id)">{{status(un.id).label}}</p>
						</td>
					</tr>
					<tr>
						<td :rowspan="cbr.length + 1">CBR</td>
					</tr>
					<tr v-for="(un, index) in cbr" :key="'cbr'+index" :class="un.loading ? 'loading':''">
						<td>{{un.text ? un.text + ' / ' + un.label:un.label}}</td>
						<td><DocumentDateSelector :documentTypeId="un.id" :dateType="1" :loading="(status) => un.loading = status"/></td>
						<td><DocumentDateSelector :documentTypeId="un.id" :dateType="2" :loading="(status) => un.loading = status"/></td>
						<td><DocumentDateSelector :documentTypeId="un.id" :dateType="3" :loading="(status) => un.loading = status"/></td>
						<td>
							<v-icon v-if="status(un.id)" :color="status(un.id).color" small>{{ status(un.id).icon }}</v-icon>
							<p class="mb-0" v-if="status(un.id)">{{status(un.id).label}}</p>
						</td>
					</tr>
					<tr>
						<td :rowspan="sii.length + 1">SII</td>
					</tr>
					<tr v-for="(un, index) in sii" :key="'sii'+index" :class="un.loading ? 'loading':''">
						<td>{{un.text ? un.text + ' / ' + un.label:un.label}}</td>
						<td><DocumentDateSelector :documentTypeId="un.id" :dateType="1" :loading="(status) => un.loading = status"/></td>
						<td><DocumentDateSelector :documentTypeId="un.id" :dateType="2" :loading="(status) => un.loading = status"/></td>
						<td><DocumentDateSelector :documentTypeId="un.id" :dateType="3" :loading="(status) => un.loading = status"/></td>
						<td>
							<v-icon v-if="status(un.id)" :color="status(un.id).color" small>{{ status(un.id).icon }}</v-icon>
							<p class="mb-0" v-if="status(un.id)">{{status(un.id).label}}</p>
						</td>
					</tr>
					<tr>
						<td :rowspan="instalations.length + 1">Sanitario</td>
					</tr>
					<tr v-for="(un, index) in instalations" :key="'instalations'+index" :class="un.loading ? 'loading':''">
						<td>{{un.text ? un.text + ' / ' + un.label:un.label}}</td>
						<td><DocumentDateSelector :documentTypeId="un.id" :dateType="1" :loading="(status) => un.loading = status"/></td>
						<td><DocumentDateSelector :documentTypeId="un.id" :dateType="2" :loading="(status) => un.loading = status"/></td>
						<td><DocumentDateSelector :documentTypeId="un.id" :dateType="3" :loading="(status) => un.loading = status"/></td>
						<td>
							<v-icon v-if="status(un.id)" :color="status(un.id).color" small>{{ status(un.id).icon }}</v-icon>
							<p class="mb-0" v-if="status(un.id)">{{status(un.id).label}}</p>
						</td>
					</tr>
					<tr>
						<td :rowspan="sec.length + 1">Sec</td>
					</tr>
					<tr v-for="(un, index) in sec" :key="'sec'+index" :class="un.loading ? 'loading':''">
						<td>{{un.text ? un.text + ' / ' + un.label:un.label}}</td>
						<td><DocumentDateSelector :documentTypeId="un.id" :dateType="1" :loading="(status) => un.loading = status"/></td>
						<td><DocumentDateSelector :documentTypeId="un.id" :dateType="2" :loading="(status) => un.loading = status"/></td>
						<td><DocumentDateSelector :documentTypeId="un.id" :dateType="3" :loading="(status) => un.loading = status"/></td>
						<td>
							<v-icon v-if="status(un.id)" :color="status(un.id).color" small>{{ status(un.id).icon }}</v-icon>
							<p class="mb-0" v-if="status(un.id)">{{status(un.id).label}}</p>
						</td>
					</tr>
				</tbody>
			</v-simple-table>
		</v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment'
import DocumentDateSelector from '@/components/Views/PreProjects/DocumentDateSelector'
export default {
  name: 'DocumentTable',
	components: {DocumentDateSelector},
	computed: {
    preproject() {
      return this.$store.state.preprojects.preProject
    }
	},
	data(){
		return{
			unarchive: [
				{ id: 1, loading: false, text: 'CIP', label: 'Certificado de Informes Previos' },
				{ id: 2, loading: false, text: 'CVS', label: 'Certificado de Vivienda Social' },
				{ id: 3, loading: false, text: 'CE', label: 'Certificado Expropiación' },
				{ id: 4, loading: false, text: 'CN', label: 'Certificado de Número' },
				{ id: 5, loading: false, text: 'CUS', label: 'Certificado Uso de Suelo' },
				{ id: 6, loading: false, text: 'PE', label: 'Permiso de Edificación' },
				{ id: 7, loading: false, text: 'RD', label: 'Recepción Definitiva' },
				{ id: 8, loading: false, text: 'RC', label: 'Resolución Copropiedad' },
				{ id: 9, loading: false, text: 'CPA', label: 'Copia Plano Arquitectura' },
				{ id: 10, loading: false, text: 'CPL', label: 'Copia Plano Estructura' },
				{ id: 11, loading: false, text: 'CPC', label: 'Copia Plano Copropiedad' },
				{ id: 12, loading: false, text: 'CPLO', label: 'Copia Plano Loteo' },
				{ id: 13, loading: false, text: 'CPSP', label: 'Copia Plano Subdivisión Predial' }
			],
			cbr: [
				{ id: 14, loading: false, text: 'PT', label: 'Copia Plano Terreno' },
				{ id: 15, loading: false, text: 'CPSP', label: 'Copia Plano Subdivisión Predial' },
				{ id: 16, loading: false, text: 'CDV', label: 'Certificado Dominio Vigente' }
			],
			sii: [
				{ id: 17, loading: false, text: 'CAFD', label: 'Certificado Avalúo Fiscal Detallado' }
			],
			instalations: [
				{ id: 18, loading: false, text: 'PA', label: 'Planos Agua Potable y Alcantarillado' }
			],
			sec: [
				{ id: 19, loading: false, text: 'CTPE', label: 'Copia TE1 y Planos Eléctricos' },
				{ id: 20, loading: false, text: 'CTOPG', label: 'Copia TC6 u Otros y Planos de Gas' }
			]
		}
	},
	methods: {
		status(documentTypeId){
			const base = [
				{ icon: 'mdi-check-circle-outline', label: 'Entregado', color: 'green' },
				{ icon: 'mdi-timer-sand', label: 'En Espera', color: 'orange' },
				{ icon: 'mdi-progress-alert', label: 'Atrasado', color: 'red' }
			]
			const found = this.preproject.documents.find((document) => {
				return document.document_type_id == documentTypeId
			})

			if (found) {
				if (found.delivered_date) {
					return base[0];
				}
				if (!found.max_date || ( found.max_date && moment().isBefore(found.max_date) ) ) {
					return base[1];
				}
				if (found.max_date && moment().isAfter(found.max_date)) {
					return base[2];
				}
			}
			return null;
		}
	}
}
</script>

<style scoped>
.custom-table th{
	color: white!important;
}
.custom-table th,
.custom-table td {
	font-size: 12px!important;
	text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.custom-table thead {
  background-color: #959595;
}
.custom-table tr.loading {
  background-image: linear-gradient(to right, transparent 50%, rgba(0, 0, 0, .05) 50%);
  background-size: 200% 100%;
  animation: loading 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

.custom-table tr.loading td {
  opacity: .45;
  pointer-events: none;
}

@keyframes loading {
  0% {
    background-position: 0;
  }
  50% {
    background-position: -30%;
  }
  80% {
    background-position: -100%;
  }
  100% {
    background-position: -200%;
  }
}
</style>
