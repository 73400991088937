const endpoints = {
	getVTReportTemplates: '/schedule/getVTReportTemplates',
	createVTReportTemplate: '/schedule/createVTReportTemplate',
	updateVTReportTemplate: '/schedule/updateVTReportTemplate',
	deleteVTReportTemplate: '/schedule/deleteVTReportTemplate',
	getStates: '/general/getStates',
	getCities: '/general/getCities',
	getCountryCities: '/general/getCountryCities',
	getPolicyAcceptance: '/general/getPolicyAcceptance',
	getPolicyUserAcceptance: '/general/getPolicyUserAcceptance',
	getCoordinatesValuesPolygon: '/schedule/getCoordinatesValuesPolygon'
}
export {endpoints}