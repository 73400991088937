import {endpoints} from './endpoints'
import request from '../request'

export function createUserChatRoom (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createUserChatRoom,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createChatRoom (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createChatRoom,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function addUserToChatRoom (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.addUserToChatRoom,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function removeUserFromChatRoom (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.removeUserFromChatRoom,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function addMessageToChatRoom (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.addMessageToChatRoom,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getUserChatRooms (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getUserChatRooms,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getChatRoomMessages (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getChatRoomMessages,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}