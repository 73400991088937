const endpoints = {
	createPreProject: '/preprojects/createPreProject',
	getCompanyPreProjects: '/preprojects/getCompanyPreProjects',
	getPreProject: '/preprojects/getPreProject',
	addFileToPreProject: '/preprojects/addFileToPreProject',
	removeFileFromPreProject: '/preprojects/removeFileFromPreProject',
	setBudgetsPartCategory: '/preprojects/setBudgetsPartCategory',
	removeBudgetPartCategory: '/preprojects/removeBudgetPartCategory',
	setPreProjectBudgetsPart: '/preprojects/setPreProjectBudgetsPart',
	addPreProjectBudgetsPart: '/preprojects/addPreProjectBudgetsPart',
	removePreProjectBudgetsPart: '/preprojects/removePreProjectBudgetsPart',
	updateDocumentRevision: '/preprojects/updateDocumentRevision',
	sendReportPreproject: '/preprojects/sendReportPreproject',
	updatePreProjectInfo: '/preprojects/updatePreProjectInfo',
	setResponsible: '/preprojects/setResponsible',
}
export {endpoints}