<template>
	<v-container fluid style="height:100%">
		<div class="chat-container d-flex">
			<v-row class="fill-height">
				<v-col cols="12" md="3" class="fill-height pa-0">
					<v-text-field
						v-model="search"
						append-icon="mdi-magnify"
						dense
						label="Buscar"
						class="ma-1 mb-2"
						flat
						outlined
						solo
						rounded
						hide-details
					></v-text-field>
					<div v-if="roomsFromSearch">
						<div class="chat-list-item d-flex justify-space-between px-2 py-2"
							v-for="(user, index) in roomsFromSearch" :key="index"
							@click="selectRoomFromSearch(user)">
							<div class="d-flex">
								<v-avatar color="#959595" size="35px" class="mr-4">
									<img :src="user.profile?.avatar" v-if="user.profile?.avatar"/>
									<v-icon v-else dark>mdi-account</v-icon>
								</v-avatar>
								<div>
									<p class="mb-0 font-weight-bold body-2">{{user.profile?.name}}</p>
									<p class="mb-0 caption">{{user.email}}</p>
								</div>
							</div>
						</div>
					</div>
					<div v-else>
						<div class="chat-list-item d-flex justify-space-between px-2 py-2"
							v-for="(room, index) in chatsRooms" :key="index"
							@click="selectChatRoom(room)">
							<div class="d-flex">
								<v-avatar color="#959595" size="35px" class="mr-4">
									<img :src="room.users[0].user?.profile?.avatar" v-if="room.users[0].user?.profile?.avatar"/>
									<v-icon v-else dark>mdi-account</v-icon>
								</v-avatar>
								<div>
									<p class="mb-0 font-weight-bold body-2">{{room.name ? room.name:room.users[0].user?.profile?.name}}</p>
									<p class="mb-0 caption">{{room.last_message?.message}}</p>
								</div>
							</div>
							<p class="mb-0 caption">{{room.last_message?.created_at | toDateTime }}</p>
						</div>
					</div>
				</v-col>
				<v-col cols="12" md="9" class="pa-0">
					<div class="chatbox-container fill-height" v-if="!selectedChatRoomId">
						<div class="messages-container nomessages">
							Selecciona una conversación
						</div>
					</div>
					<div class="chatbox-container fill-height" v-else>
						<Messages :selectedChatRoomId="selectedChatRoomId"/>
					</div>
				</v-col>
			</v-row>
		</div>
	</v-container>
</template>

<script>
import Messages from '@/components/Views/Chat/Messages'
import {getChatRoomMessages, addMessageToChatRoom, createUserChatRoom} from '@/helpers/api/chats'
export default {
	components: {Messages},
	data() {
		return {
			search: '',
			new_message: null,
			loadingAddChat: false,
			conversationMessages: [],
			selectedChatRoomId: null,
			requestInterval: null
		};
	},
  computed: {
		roomsFromSearch(){
			if(typeof this.search === 'string' && this.search.trim() !== ''){
				const users = this.$store.state.users.usersList.filter(user => {
					return (
						( user.profile?.name.toLowerCase().includes(this.search.toLowerCase()) ||
						user.email.toLowerCase().includes(this.search.toLowerCase()) ) &&
						user.id != this.$store.state.user?.userInfo?.id
					)
				})
				return users
			}
			return null
		},
		chatsRooms(){
			return this.$store.state.chats.chats
		},
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		}
  },
	watch: {
		chatsRooms(newValue){
			if(newValue && newValue.length > 0 && parseInt(this.$route.query.user_id)){
				this.selectRoomFromSearch({id: parseInt(this.$route.query.user_id)})
			}
		},
		conversationMessages(){
			setTimeout(() => {
				this.scrollToBottom()
			}, 300);
			this.$nextTick(() => {
				if (this.$refs.messageInput) {
					this.$refs.messageInput.focus();
				}
			});
		},
		selectedCompany(newValue){
			if(newValue){
				this.$store.dispatch('chats/getUserChats')
				this.$store.dispatch('users/getUserFromCompany', {start: 0, limit: 300})
			}
		},
		selectedChatRoomId(newValue, oldValue){
			if(newValue && newValue != oldValue){
				this.getSelectedChatRoomMessages()
			}
		}
	},
	beforeDestroy(){
		clearInterval(this.requestInterval)
		this.requestInterval = null
	},
	mounted(){
		this.scrollToBottom()
		if(this.selectedCompany){
			this.$store.dispatch('chats/getUserChats')
			this.$store.dispatch('users/getUserFromCompany', {start: 0, limit: 300})
		}
		if(this.chatsRooms && this.chatsRooms.length > 0 && parseInt(this.$route.query.user_id)){
			this.selectRoomFromSearch({id: parseInt(this.$route.query.user_id)})
		}
		this.requestInterval = setInterval(() => {
			if(this.selectedChatRoomId){
				this.getSelectedChatRoomMessages()
			}
		}, 4000);
	},
	methods: {
		selectRoomFromSearch(user){
			const found = this.chatsRooms.find((room) => {
				return user.id == room.users[0].user_id
			})
			if(found){
				this.selectChatRoom(found)
			}
			else{
				this.createNewChatRoom(user)
			}
		},
		async createNewChatRoom(user){
			const user_id = user.id
			const room = await createUserChatRoom({user_id})
			if(room.code == 200){
				this.selectChatRoom(room.chat_room)
			}
		},
    scrollToBottom() {
      const container = this.$refs.messagesContainer;
			if(container){
				container.scrollTop = container.scrollHeight;
			}
    },
		isMyMessage(message){
			return message.user_id == this.$store.state.user?.userInfo?.id
		},
		selectChatRoom(room){
			this.selectedChatRoomId = room.id
		},
		async getSelectedChatRoomMessages(){
			const chat_room_id = this.selectedChatRoomId
			const messages = await getChatRoomMessages({chat_room_id})
			if(messages.code == 200){
				this.conversationMessages = messages.messages
			}
		},
		async sendMessage(){
			if(typeof this.new_message === 'string' && this.new_message.trim() !== ''){
				this.appendNewMessageTemp(this.new_message)
				const data = {
					chat_room_id: this.selectedChatRoomId,
					message: this.new_message
				}
				this.new_message = null
				const new_message = await addMessageToChatRoom(data)
				if(new_message.code == 200){
					this.conversationMessages = new_message.chat_room_messages
					this.$store.dispatch('chats/getUserChats')
				}
			}
		},
		appendNewMessageTemp(message){
			const add_message = {
				message,
				user_id: this.$store.state.user?.userInfo?.id
			}
			this.conversationMessages.push(add_message)
		},
	},
};
</script>

<style scoped>
	.chat-container{
		height: 100%;
	}
	.chat-list-item{
		cursor: pointer;
	}
	.chat-list-item:hover{
		background: #FCFBFE;
	}

	.v-text-field {
		align-items: center;
	}
	.chatbox-container{
		display: flex;
		flex-direction: column;
		background: white;
	}
	.messages-container{
		display: flex;
		flex-direction: column;
		padding: 10px;
		flex: 1;
		height: 100%;
		background-color: #FCFBFE;
		overflow-y: scroll;
		text-transform: none;
	}
	.user-input-container{
		flex: 0;
	}
	.user-input-inner-container{
		padding: 10px;
	}
	.single-message{
		background-color: rgb(213, 211, 211);
		border: 1px solid rgb(193, 191, 191);
		padding: 4px 8px;
		max-width: 80%;
		border-radius: 10px;
		font-size: 14px;
		margin-bottom: 8px;
		text-transform: none;
	}
	.single-message.self-user{
		background-color: rgb(217, 239, 217);
		border: 1px solid rgb(197, 232, 197);
		align-self: flex-end;
	}
	.nomessages{
		text-align: center;
		font-size: 14px;
		padding-top: 30px;
	}
</style>
<style>
	.chatbox-container .single-message p{
		margin-bottom: 0;
	}
</style>