import { getUserChatRooms } from "@/helpers/api/chats"

const ChatStore = {
  namespaced: true,
  state: {
		loadingChats: false,
		chats: []
  },
  mutations: {
    setLoadingChats(state, status) {
      state.loadingChats = status
    },
    setChats(state, chats) {
      state.chats = chats
    }
  },
  actions: {
    async getUserChats({ commit }) {
      commit('setLoadingChats', true)
      const chat = await getUserChatRooms()
      if (chat.code === 200) {
        commit('setChats', chat.chat_room)
      }
      commit('setLoadingChats', false)
    }
  }
}

export default ChatStore
