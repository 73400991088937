<template>
	<div>
		<NewUserModal v-if="$store.state.users.newUserModal"/>
		<EditPermisosUserModal v-if="$store.state.users.editPermisosUserModal"/>
		<CompanyConceptModal v-if="$store.state.companies.newCompanyConceptModal"/>
		<CompanyModal v-if="$store.state.companies.newCompanyModal"/>
		<CompanyModalFirst v-if="$store.state.companies.newCompanyModalFirst && $store.state.user.userInfo"/>
		<RoleModal v-if="$store.state.companies.newRoleModal"/>
		<ProjectTypesModal v-if="$store.state.companies.newProjectTypeModal"/>
		<ReportTemplateModal v-if="$store.state.vt.newReportTemplateModal"/>
		<Schedule v-if="$store.state.schedule.newScheduleModal"/>
		<AgendaClosure v-if="$store.state.vt.agendaClosureModal"/>
		<NewCrmContactModal v-if="$store.state.crm.newContactModal"/>
		<NewCrmContactModal v-if="$store.state.crm.newContactModal"/>
		<NewPreProjectModal v-if="$store.state.preprojects.newPreProjectModal"/>
		<BudgetModal v-if="$store.state.general.newBudgetModal?.status"/>
		<BudgetConceptsModal v-if="$store.state.budget.newBudgetConceptModal" />
		<BudgetCategoryModal v-if="$store.state.budget.newBudgetCategoryModal" />
		<BudgetDetailModal v-if="$store.state.budget.newBudgetDetailModal" />
		<UserBillingInfoModal v-if="$store.state.general.userTypePromptModal" />
	</div>
</template>

<script>
import NewUserModal from './Users/NewUserModal'
import EditPermisosUserModal from './Users/EditPermisosUserModal'
import CompanyConceptModal from './Company/CompanyConceptModal'
import CompanyModal from './Company/CompanyModal'
import CompanyModalFirst from './Company/CompanyModalFirst'
import RoleModal from './Company/RoleModal'
import ProjectTypesModal from './Company/ProjectTypesModal'
import ReportTemplateModal from './Schedules/ReportTemplateModal'
import Schedule from './Schedules/Schedule'
import AgendaClosure from './Schedules/AgendaClosure'
import NewCrmContactModal from './CRM/NewCrmContactModal'
import NewPreProjectModal from './PreProject/NewPreProjectModal'
import BudgetModal from './Budgets/BudgetModal'
import BudgetConceptsModal from './Budgets/BudgetConceptsModal'
import BudgetCategoryModal from './Budgets/BudgetCategoryModal'
import BudgetDetailModal from './Budgets/BudgetDetailModal'
import UserBillingInfoModal from './General/UserBillingInfoModal'

export default {
	name: 'GlobalModalsIndex',
	components: {
		NewUserModal,
		EditPermisosUserModal,
		CompanyModal,
		CompanyConceptModal,
		RoleModal,
		ReportTemplateModal,
		Schedule,
		AgendaClosure,
		NewCrmContactModal,
		CompanyModalFirst,
		ProjectTypesModal,
		NewPreProjectModal,
		BudgetModal,
		BudgetConceptsModal,
		BudgetCategoryModal,
		BudgetDetailModal,
		UserBillingInfoModal
	},
	data: () => ({
		//
	}),
	mounted(){
	}
};
</script>
