import {getBudgetConcepts, getBudgetCompanyCategories, getBudgetCompanyDetails} from '@/helpers/api/budgets'

const messagesStore = {
  namespaced: true,
  state: {
		loadingBudgetConcepts: false,
		companiesBudgetConcepts: [],
		newBudgetConceptModal: false,
		newBudgetConceptModalEditing: null,
		loadingBudgetCategories: false,
		companiesBudgetCategories: [],
		newBudgetCategoryModal: false,
		newBudgetCategoryModalEditing: null,
		loadingBudgetDetails: false,
		companiesBudgetDetails: [],
		newBudgetDetailModal: false,
		newBudgetDetailModalEditing: null
  },
  mutations: {
		setNewBudgetConceptModal(state, value){
			state.newBudgetConceptModal = value
		},
		setNewBudgetConceptModalEditing(state, value){
			state.newBudgetConceptModalEditing = value
		},
		setLoadingBudgetConcepts(state, value){
			state.loadingBudgetConcepts = value
		},
		setCompaniesBudgetConcepts(state, value){
			state.companiesBudgetConcepts = value
		},
		setNewBudgetCategoryModal(state, value){
			state.newBudgetCategoryModal = value
		},
		setNewBudgetCategoryModalEditing(state, value){
			state.newBudgetCategoryModalEditing = value
		},
		setLoadingBudgetCategories(state, value){
			state.loadingBudgetCategories = value
		},
		setCompaniesBudgetCategories(state, value){
			state.companiesBudgetCategories = value
		},
		setNewBudgetDetailModal(state, value){
			state.newBudgetDetailModal = value
		},
		setNewBudgetDetailModalEditing(state, value){
			state.newBudgetDetailModalEditing = value
		},
		setLoadingBudgetDetails(state, value){
			state.loadingBudgetDetails = value
		},
		setCompaniesBudgetDetails(state, value){
			state.companiesBudgetDetails = value
		},
  },
  actions: {
    async getBudgetConcepts({ commit }) {
      commit('setLoadingBudgetConcepts', true)
      const data = {
        start: 0,
        limit: 300
      }
      const concepts = await getBudgetConcepts(data)
      if (concepts.code === 200) {
        commit('setCompaniesBudgetConcepts', concepts.budget_concepts)
      }
      commit('setLoadingBudgetConcepts', false)
    },
    async getBudgetCategories({ commit }) {
      commit('setLoadingBudgetCategories', true)
      const data = {
        start: 0,
        limit: 300
      }
      const categories = await getBudgetCompanyCategories(data)
      if (categories.code === 200) {
        commit('setCompaniesBudgetCategories', categories.budget_categories)
      }
      commit('setLoadingBudgetCategories', false)
    },
    async getBudgetDetails({ commit }) {
      commit('setLoadingBudgetDetails', true)
      const data = {
        start: 0,
        limit: 300
      }
      const details = await getBudgetCompanyDetails(data)
      if (details.code === 200) {
        commit('setCompaniesBudgetDetails', details.budget_details)
      }
      commit('setLoadingBudgetDetails', false)
    },
  }
}

export default messagesStore
