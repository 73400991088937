import Vue from 'vue'
import VueRouter from 'vue-router'

import userStore from '@/stores/UserStore'

import PrivacyPolicies from '@/components/Pages/PrivacyPolicies'
import Login from '@/components/Pages/Login'
import Register from '@/components/Pages/Register'
import ResetPassRequest from '@/components/Pages/ResetPassRequest'
import ResetPass from '@/components/Pages/ResetPass'
import FirstTimePass from '@/components/Pages/FirstTimePass'
import NewUserPassword from '@/components/Pages/NewUserPassword'

import Dashboard from '@/components/Views/Dashboard/Dashboard'
import Users from '@/components/Views/Users'
import CRM from '@/components/Views/CRM'
import Conversation from '@/components/Views/CRM/Conversation'
import CompanyConcepts from '@/components/Views/CRM/CompanyConcepts'

import Companies from '@/components/Views/Companies'
import Roles from '@/components/Views/Companies/Roles'
import ProjectTypes from '@/components/Views/Companies/ProjectTypes'

import VTMap from '@/components/Views/VTMap'

import Schedules from '@/components/Views/Schedules'

import Policies from '@/components/Views/Policies'
import UserDetailPolicies from '@/components/Views/Policies/UserDetail'

import Clients from '@/components/Views/Clients'
import ClientDetail from '@/components/Views/Clients/ClientDetail'
import MyClientDetail from '@/components/Views/Clients/MyClientDetail'

import PreProjects from '@/components/Views/PreProjects'
import PreProject from '@/components/Views/PreProjects/PreProject'

import Chat from '@/components/Views/Chat'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history', // https://router.vuejs.org/api/#mode
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes(){
	return [
    {
      path: '/policies',
      name: 'PrivacyPolicies',
      meta: { label: 'PrivacyPolicies'},
      component: PrivacyPolicies
    },
    {
      path: '/newuser',
      name: 'NewUserPassword',
      meta: { label: 'NewUserPassword'},
      component: NewUserPassword
    },
    {
      path: '/login',
      name: 'Login',
      meta: { label: 'Login'},
      component: Login
    },
    {
      path: '/register',
      name: 'Register',
      meta: { label: 'Register'},
      component: Register
    },
    {
      path: '/reset-pass-request',
      name: 'ResetPassRequest',
      meta: { label: 'ResetPassRequest'},
      component: ResetPassRequest
    },
    {
      path: '/reset-pass/:token',
      name: 'ResetPass',
      meta: { label: 'ResetPass'},
      component: ResetPass
    },
    {
      path: '/set-new-pass/:token',
      name: 'FirstTimePass',
      meta: { label: 'FirstTimePass'},
      component: FirstTimePass
    },
    {
      path: '/',
      name: 'Dashboard',
      meta: { label: 'Panel de Control'},
      component: Dashboard
    },
    {
      path: '/users',
      name: 'Users',
      meta: { label: 'Listado de Usuarios', requiredPermissions: ['view_users'] },
      component: Users
    },
    {
      path: '/crm',
      name: 'CRM',
      meta: { label: 'CRM / Solicitudes', requiredPermissions: ['view_crm'] },
      component: CRM
    },
    {
      path: '/crm/conversation/:id',
      name: 'Conversation',
      meta: { label: 'Ficha Visita Técnica', requiredPermissions: ['view_crm'] },
      component: Conversation
    },
    {
      path: '/crm/concepts',
      name: 'CompanyConcepts',
      meta: { label: 'Conceptos de la empresa', requiredPermissions: ['view_company_concepts'] },
      component: CompanyConcepts
    },
    {
      path: '/companies',
      name: 'Companies',
      meta: { label: 'Mis Empresas' },
      component: Companies
    },
    {
      path: '/company-roles',
      name: 'Roles',
      meta: { label: 'Roles Empresa', requiredPermissions: ['view_company_roles'] },
      component: Roles
    },
    {
      path: '/project-types',
      name: 'ProjectTypes',
      meta: { label: 'Tipos de Proyecto', requiredPermissions: ['view_company_project_types'] },
      component: ProjectTypes
    },
    {
      path: '/vt-map',
      name: 'VTMap',
      meta: { label: 'Mapa Valores Visitas Técnicas', requiredPermissions: ['view_vt_maps'] },
      component: VTMap
    },
    {
      path: '/schedule',
      name: 'Schedules',
      meta: { label: 'Agenda', requiredPermissions: ['schedule_access'] },
      component: Schedules
    },
    {
      path: '/accepted_policies',
      name: 'Policies',
      meta: { label: 'Aceptación Políticas' },
      component: Policies
    },
    {
      path: '/user_policy/:user_id',
      name: 'UserDetailPolicies',
      meta: { label: 'Políticas Aceptadas Usuario', requiredPermissions: ['super_admin_rights'] },
      component: UserDetailPolicies
    },
    {
      path: '/clients',
      name: 'Clients',
      meta: { label: 'Clientes', requiredPermissions: ['view_clients'] },
      component: Clients
    },
    {
      path: '/clients/:id',
      name: 'Client',
      meta: { label: 'Cliente', requiredPermissions: ['view_clients'] },
      component: ClientDetail
    },
    {
      path: '/client',
      name: 'ClientDetail',
      meta: { label: 'Cliente', requiredPermissions: ['client_capabilities'] },
      component: MyClientDetail
    },
    {
      path: '/preprojects',
      name: 'PreProjects',
      meta: { label: 'Gestión Antecedentes', requiredPermissions: ['view_preprojects'] },
      component: PreProjects
    },
    {
      path: '/preprojects/:id',
      name: 'PreProject',
      meta: { label: 'Gestión Antecedentes', requiredPermissions: ['view_preprojects'] },
      component: PreProject
    },
    {
      path: '/chat',
      name: 'Chat',
      meta: { label: 'Chat' },
      component: Chat
    }
	]
}

router.beforeEach((to, from, next) => {
  const requiredPermissions = to.meta.requiredPermissions || [];
	const userPermissions = userStore.state.companyPermissions

  if (to.name !== 'Login' && to.name !== 'Register' && to.name !== 'ResetPassRequest' && to.name !== 'ResetPass' && to.name !== 'NewUserPassword' && to.name !== 'PrivacyPolicies' && to.name !== 'FirstTimePass' && !userStore.state.isAuthenticated) next({ name: 'Login' })
  else if ((to.name === 'Login' || to.name === 'Register' || to.name === 'NewUserPassword') && userStore.state.isAuthenticated) next({ name: 'Dashboard' })
	else if (requiredPermissions.length > 0) {
		if(!userPermissions || userPermissions && Object.keys(userPermissions).length <= 0){
			next({ name: 'Dashboard' })
		}
		const hasPermission = requiredPermissions.every(permission =>
			userPermissions && userPermissions[permission] === true
		);

		if (!hasPermission) {
			return null
		}
		next()
	}
  else next()
})

export default router