const endpoints = {
	login: '/auth/login',
	register: '/auth/signup',
	resetPassRequest: '/auth/resetpassrequest',
	resetPass: '/auth/resetpass',
	userInfo: '/user/info',
	setUserBillingInfo: '/user/setUserBillingInfo',
	getUserBillingInfo: '/user/getUserBillingInfo',
	loginWithGoogleToken: '/social-auth/loginWithGoogleToken',
}
export {endpoints}