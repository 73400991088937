import { getUser } from '@/helpers/api/user'
import router from '@/router'

const companyPermissions = localStorage.getItem('company_permissions')

const userStore = {
  namespaced: true,
  state: {
    userInfo: null,
    isAuthenticated: !!localStorage.getItem('userToken'),
    userCompanies: [],
    companyPermissions: companyPermissions ? JSON.parse(companyPermissions):null,
    selectedCompany: null
  },
  mutations: {
    setUserInfo(state, user) {
      state.userInfo = user
      state.isAuthenticated = true
    },
    setIsAuthenticated(state, status) {
      state.isAuthenticated = status
    },
    setUserCompanies(state, companies) {
      state.userCompanies = companies
    },
    logoutUser(state) {
      state.userInfo = null
      state.isAuthenticated = false
      localStorage.removeItem('userToken')
      localStorage.removeItem('company_id')
    },
    setCompanyPermissions(state, permissions) {
      state.companyPermissions = permissions
			localStorage.setItem('company_permissions', JSON.stringify(permissions))
    },
    setSelectedCompany(state, company) {
      state.selectedCompany = company
    }
  },
  getters: {
    authState: state => state.isAuthenticated
  },
  actions: {
    async getUserInfo({ commit, dispatch }) {
      const user = await getUser()
      if (user.code === 200) {
        commit('setUserInfo', user.user)
        commit('setUserCompanies', user.user.companies)
				if(user.user.companies.length > 0){
					const company_id = localStorage.getItem('company_id')
					let set_company = user.user.companies[0]
					if(company_id){
						const company = user.user.companies.find((company) => {
							return company.company_id == company_id
						})
						set_company = company
						commit('companies/setSelectedCompany', set_company, { root: true })
						commit('setCompanyPermissions', set_company.capabilities)
					}
					else{
						commit('companies/setSelectedCompany', user.user.companies[0], { root: true })
						commit('setCompanyPermissions', user.user.companies[0].capabilities)
						localStorage.setItem('company_id', user.user.companies[0].company_id)
					}
					dispatch('companies/getCompaniesList', null, { root: true })
					dispatch('notifications/getNotifications', null, { root: true })

					if(set_company.role_id === 3){
						router.push({name: 'ClientDetail'})
					}
				}
				else{
					commit('companies/setNewCompanyModalFirst', true, { root: true })
				}

				if(user.user && !user.user.billing_info){
					commit('general/setUserTypePromptModal', true, { root: true })
				}
      }
    }
  }
}

export default userStore
