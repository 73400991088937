<template>
	<div class="d-flex flex-column flex-grow-1">
		<div class="chat-container d-flex flex-column flex-grow-1">
			<div class="messages-container" ref="messagesContainer" v-if="conversationMessages.length > 0">
				<div
					v-for="(message, index) in conversationMessages"
					:key="index"
					class="single-message" :class="isMyMessage(message) ? 'self-user':''">
					<p class="mb-0 mr-4">{{message?.message}}</p>
					<div class="text-right caption text--secondary">
						<small>{{message.created_at | toDateTime}}</small>
					</div>
				</div>
			</div>
			<div class="messages-container nomessages" v-else>
				Escribe un mensaje para iniciar la conversación
			</div>
		</div>
		<div class="user-input-container">
			<v-progress-linear indeterminate v-if="loadingAddChat"></v-progress-linear>
			<div class="user-input-inner-container">
				<v-text-field
					ref="messageInput"
					v-model="new_message"
					placeholder="Escribe un mensaje"
					hide-details
					dense
					outlined
					required
					:disabled="loadingAddChat"
					@keydown.enter="sendMessage"
				>
					<template v-slot:append-outer>
						<v-btn icon @click="sendMessage">
							<v-icon>mdi-send</v-icon>
						</v-btn>
					</template>
				</v-text-field>
			</div>
		</div>
	</div>
</template>

<script>
import {getChatRoomMessages, addMessageToChatRoom} from '@/helpers/api/chats'
export default {
	props: {
		selectedChatRoomId: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			search: '',
			new_message: null,
			loadingAddChat: false,
			conversationMessages: [],
			requestInterval: null
		};
	},
  computed: {
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		}
  },
	watch: {
		chatsRooms(newValue){
			if(newValue && newValue.length > 0 && parseInt(this.$route.query.user_id)){
				this.selectRoomFromSearch({id: parseInt(this.$route.query.user_id)})
			}
		},
		conversationMessages(){
			setTimeout(() => {
				this.scrollToBottom()
			}, 300);
		},
		selectedCompany(newValue){
			if(newValue){
				this.$store.dispatch('chats/getUserChats')
				this.$store.dispatch('users/getUserFromCompany', {start: 0, limit: 300})
			}
		},
		selectedChatRoomId(newValue, oldValue){
			if(newValue && newValue != oldValue){
				this.getSelectedChatRoomMessages()
			}
		}
	},
	beforeDestroy(){
		clearInterval(this.requestInterval)
		this.requestInterval = null
	},
	mounted(){
		this.scrollToBottom()
		if(this.selectedCompany){
			this.$store.dispatch('chats/getUserChats')
			this.$store.dispatch('users/getUserFromCompany', {start: 0, limit: 300})
		}
		
		if(this.selectedChatRoomId){
			this.getSelectedChatRoomMessages()
		}
		this.requestInterval = setInterval(() => {
			if(this.selectedChatRoomId){
				this.getSelectedChatRoomMessages()
			}
		}, 4000);
	},
	methods: {
    scrollToBottom() {
      const container = this.$refs.messagesContainer;
			if(container){
				container.scrollTop = container.scrollHeight;
			}
    },
		isMyMessage(message){
			return message.user_id == this.$store.state.user?.userInfo?.id
		},
		async getSelectedChatRoomMessages(){
			const chat_room_id = this.selectedChatRoomId
			const messages = await getChatRoomMessages({chat_room_id})
			if(messages.code == 200){
				this.conversationMessages = messages.messages
			}
		},
		async sendMessage(){
			if(typeof this.new_message === 'string' && this.new_message.trim() !== ''){
				this.appendNewMessageTemp(this.new_message)
				const data = {
					chat_room_id: this.selectedChatRoomId,
					message: this.new_message
				}
				this.new_message = null
				const new_message = await addMessageToChatRoom(data)
				if(new_message.code == 200){
					this.conversationMessages = new_message.chat_room_messages
					this.$store.dispatch('chats/getUserChats')
				}
			}
		},
		appendNewMessageTemp(message){
			const add_message = {
				message,
				user_id: this.$store.state.user?.userInfo?.id
			}
			this.conversationMessages.push(add_message)
		},
	},
};
</script>

<style scoped>
	/* .chat-container{
		flex: 1;
	} */
	.chat-list-item{
		cursor: pointer;
	}
	.chat-list-item:hover{
		background: #FCFBFE;
	}

	.v-text-field {
		align-items: center;
	}
	.chatbox-container{
		display: flex;
		flex-direction: column;
		background: white;
	}
	.messages-container{
		display: flex;
		flex-direction: column;
		padding: 10px;
		flex: 1;
		background-color: #FCFBFE;
		overflow-y: scroll;
		text-transform: none;
	}
	.user-input-container{
		flex: 0;
	}
	.user-input-inner-container{
		padding: 10px;
	}
	.single-message{
		background-color: rgb(213, 211, 211);
		border: 1px solid rgb(193, 191, 191);
		padding: 4px 8px;
		max-width: 80%;
		border-radius: 10px;
		font-size: 14px;
		margin-bottom: 8px;
		text-transform: none;
	}
	.single-message.self-user{
		background-color: rgb(217, 239, 217);
		border: 1px solid rgb(197, 232, 197);
		align-self: flex-end;
	}
	.nomessages{
		text-align: center;
		font-size: 14px;
		padding-top: 30px;
	}
</style>
<style>
	.chatbox-container .single-message p{
		margin-bottom: 0;
	}
</style>