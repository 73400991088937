import {endpoints} from './endpoints'
import request from '../request'

export function createBudgetConcept (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createBudgetConcept,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateBudgetConcept (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateBudgetConcept,
			method: 'post',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getBudgetConcepts (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getBudgetConcepts,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function removeBudgetConcept (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.removeBudgetConcept,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createBudgetCompanyCategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createBudgetCompanyCategory,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateBudgetCompanyCategory (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateBudgetCompanyCategory,
			method: 'post',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getBudgetCompanyCategories (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getBudgetCompanyCategories,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function removeBudgetCompanyCategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.removeBudgetCompanyCategory,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createBudgetCompanyDetail (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createBudgetCompanyDetail,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateBudgetCompanyDetail (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateBudgetCompanyDetail,
			method: 'post',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getBudgetCompanyDetails (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getBudgetCompanyDetails,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function removeBudgetCompanyDetail (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.removeBudgetCompanyDetail,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateOrderBudgetDetail (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateOrderBudgetDetail,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}