<template>
  <v-container fluid class="crm-conversation">
    <v-row class="mb-4" v-if="$store.state.crm.loadingConversation">
      <v-col cols="12">
				<div class="d-flex flex-column justify-center align-center">
					<v-progress-circular
						:size="50"
						:width="5"
						color="rgba(0,0,0,0.2)"
						class="mb-3"
						indeterminate
					></v-progress-circular>
					Cargando Información
				</div>
      </v-col>
    </v-row>
		<v-row class="mb-4" v-else-if="conversation">
			<!-- <v-col col="12">
				<v-tabs
					v-model="tab"
					background-color="transparent"
					grow
				>
					<v-tab
						v-for="item in items"
						:key="item"
					>
						{{ item }}
					</v-tab>
				</v-tabs>

				<v-tabs-items v-model="tab">
					<v-tab-item
						v-for="item in items"
						:key="item"
					>
						<v-card
							color="basil"
							flat
						>
							<v-card-text>asdasda</v-card-text>
						</v-card>
					</v-tab-item>
				</v-tabs-items>
			</v-col> -->
      <v-col cols="12" md="5">
        <v-card class="mb-4">
          <v-card-text class="py-2">
						<div class="d-flex align-center justify-space-between">
							<div class="d-flex align-center">
								<p class="font-weight-bold body-2 px-1 mb-0 mr-2">Datos Generales</p>
								<v-chip
									color="#524D4D"
									label
									outlined
									x-small
									class="px-1"
								>
									<b>{{ conversation.mode == 1 ? 'Manual':'Automático' }}</b>
								</v-chip>
							</div>
						</div>
						<v-divider class="my-2"></v-divider>
						<div class="px-0 py-0">
							<div class="d-flex justify-space-between mb-1 px-1 item-background-hover">
								<div style="width: 150px">ID CRM/Solicitud</div><span>:</span> <b class="text-end" style="flex: 1;">{{conversation.id}}</b>
								<div class="action-container"></div>
							</div>
							<div class="d-flex justify-space-between mb-1 px-1 item-background-hover">
								<div style="width: 150px">Fecha de creación prospecto</div><span>:</span> <b class="text-end" style="flex: 1;">{{conversation.created_at | toDateTime}} hrs.</b>
								<div class="action-container"></div>
							</div>

							<div class="d-flex justify-space-between align-center" v-if="editProjectDetail">
								<v-text-field
									v-model="newProjectDetail"
									label="Proyecto"
									placeholder="Detalle proyecto"
									outlined
									required
									dense
									hide-details=""
									maxlength="255"
									class="my-1"
								>
									<template v-slot:append-outer>
										<v-btn
											icon
											color="error"
											@click="editProjectDetail = false"
											:disabled="!editPermission"
										>
											<v-icon>mdi-close</v-icon>
										</v-btn>
										<v-btn
											icon
											color="warning"
											@click="sendEditProjectDetail"
											:disabled="!editPermission"
										>
											<v-icon>mdi-check</v-icon>
										</v-btn>
									</template>
								</v-text-field>
							</div>
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover" v-else>
								<p class="d-flex mb-0 justify-space-between" style="width: 100%"><span>Detalle Proyecto:</span> <b class="ml-1">{{conversation.project_info?.project_detail}}</b></p>
								<div class="action-container">
									<v-btn
										icon
										color="warning"
										x-small
										@click="startEditProjectDetail"
										:disabled="!editPermission"
									>
										<v-icon>mdi-pencil-outline</v-icon>
									</v-btn>
								</div>
							</div>
						</div>
						<v-divider class="my-1"></v-divider>
						<div class="d-flex align-center justify-space-between">
							<div class="d-flex align-center">
								<p class="font-weight-bold body-2 px-1 mb-0 mr-2">Datos Usuario</p>
								<v-btn
									icon
									color="#524D4D"
									@click="editUser = !editUser"
									:disabled="!editPermission"
								>
									<v-icon>{{editUser ? 'mdi-close':'mdi-account-box-edit-outline'}}</v-icon>
								</v-btn>
							</div>
						</div>
						<v-divider class="my-1"></v-divider>
						<div class="py-2" v-if="conversation.user">
							<div v-if="editUser">
								<div class="d-flex justify-space-between align-center mb-1">
									<span class="mr-5">Nombre: </span>
                  <v-text-field
                    v-model="userData.name"
                    outlined
                    required
                    dense
										hide-details
										style="max-width: 75%"
                  ></v-text-field>
								</div>
								<div class="d-flex justify-space-between align-center mb-1">
									<span class="mr-5">Email: </span>
                  <v-text-field
                    v-model="userData.email"
                    outlined
                    required
                    dense
										hide-details
										style="max-width: 75%"
                  ></v-text-field>
								</div>
								<div class="d-flex justify-space-between align-center mb-1">
									<span class="mr-5">Teléfono: </span>
                  <v-text-field
                    v-model="userData.phone"
                    outlined
                    required
                    dense
										style="max-width: 75%"
                    :rules="phoneRule"
										maxlength="11"
										@keypress="isNumberKey"
                  ></v-text-field>
								</div>
								<v-btn
									depressed
									color="#524D4D"
									dark
									block
									small
									class="mt-3"
									:loading="loadingEditUser"
									@click="editUserData()"
								>
									<v-icon small>mdi-account-edit</v-icon>
									<p class="mb-0 ml-2 caption">Confirmar Cambios</p>
								</v-btn>
							</div>
							<div v-else>
								<div class="d-flex justify-space-between mb-1 px-1 item-background-hover">
									<div style="width: 150px">Nombre</div><span>:</span> <b class="text-end" style="flex: 1;">{{conversation.user.name}}</b>
									<div class="action-container"></div>
								</div>
								<div class="d-flex justify-space-between mb-1 px-1 item-background-hover">
									<div style="width: 150px">Email</div><span>:</span> <b class="text-end" style="flex: 1;">{{conversation.user.email}}</b>
									<div class="action-container"></div>
								</div>
								<div class="d-flex justify-space-between mb-1 px-1 item-background-hover">
									<div style="width: 150px">Teléfono</div><span>:</span> <b class="text-end" style="flex: 1;">{{conversation.user.phone}}</b>
									<div class="action-container"></div>
								</div>
							</div>
						</div>
						<div class="pa-2" v-else>
							No se logró obtener datos de usuario
						</div>
          </v-card-text>
        </v-card>
        <v-card class="mb-4">
          <v-card-text class="py-2">
						<div class="d-flex align-center justify-space-between">
							<p class="font-weight-bold body-2 px-1 mb-0">Agenda - Visita Técnica</p>
							<v-btn
								icon
								color="#524D4D"
								v-if="!conversation.schedule"
								@click="editCrmSchedule"
								:disabled="!editPermission"
							>
								<v-icon>mdi-invoice-clock-outline</v-icon>
							</v-btn>
						</div>
						<v-divider class="my-1"></v-divider>
						<div class="py-2" v-if="conversation.schedule">
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover" v-if="editScheduleTitle">
								<v-text-field
									v-model="newScheduleTitle"
									label="Nuevo Título Agenda"
									placeholder="Ingrese el título"
									outlined
									required
									dense
									hide-details=""
									maxlength="255"
									class="my-1"
								>
									<template v-slot:append-outer>
										<v-btn
											icon
											color="error"
											@click="editScheduleTitle = false"
											:disabled="!editPermission"
										>
											<v-icon>mdi-close</v-icon>
										</v-btn>
										<v-btn
											icon
											color="warning"
											@click="sendEditScheduleTitle"
											:disabled="!editPermission"
										>
											<v-icon>mdi-check</v-icon>
										</v-btn>
									</template>
								</v-text-field>
							</div>
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover" v-else>
								<div class="d-flex justify-space-between" style="width: 100%"><div style="width: 150px">Título Agenda</div><span>:</span> <b class="text-end" style="flex: 1;">{{conversation.schedule.user_schedule?.title}}</b></div>
								<div class="action-container">
									<v-btn
										icon
										color="warning"
										x-small
										@click="startEditTitle"
										:disabled="!editPermission"
									>
										<v-icon>mdi-pencil-outline</v-icon>
									</v-btn>
								</div>
							</div>
							<div class="d-flex justify-space-between mb-1 px-1 item-background-hover">
								<div style="width: 150px">Fecha agendada</div><span>:</span> <b class="text-end" style="flex: 1;">{{conversation.schedule.vt_date | toDateTime}} hrs.</b>
								<div class="action-container"></div>
							</div>
							<div class="d-flex justify-space-between align-center" v-if="editAddress">
								<v-autocomplete
									v-model="newAddress.address"
									:items="[...itemsAddress, ...[conversation.project_info?.project_address]]"
									name="address"
									:search-input.sync="search"
									prepend-inner-icon="mdi-map-marker"
									label="Dirección"
									placeholder="Dirección"
									autocomplete="off"
									outlined
									dense
									hide-details
									:cache-items="cachingItems"
									:loading="loadingAddress"
									:filter="d => d"
									color="secondary"
									item-color="secondary"
									return-object
									@change="change"
								></v-autocomplete>
								<v-btn
									icon
									small
									color="error"
									@click="editAddress = false"
									:disabled="!editPermission"
								>
									<v-icon>mdi-close</v-icon>
								</v-btn>
							</div>
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover" v-else>
								<div class="d-flex justify-space-between" style="width: 100%"><div style="width: 150px">Dirección</div><span>:</span> <b class="text-end" style="flex: 1;">{{conversation.project_info?.project_address}}</b></div>
								<div class="action-container">
									<v-btn
										icon
										color="warning"
										x-small
										@click="startEditAddress"
										:disabled="!editPermission"
									>
										<v-icon>mdi-vector-square-edit</v-icon>
									</v-btn>
								</div>
							</div>
							<div class="d-flex justify-space-between align-center" v-if="editValue">
								<v-text-field
									v-model="newValueCrm"
									label="Nuevo Valor visita técnica"
									placeholder="Ingrese el valor"
									outlined
									required
									dense
									hide-details=""
									maxlength="9"
									class="my-1"
									@keypress="isNumberKey"
								>
									<template v-slot:append-outer>
										<v-btn
											icon
											color="error"
											@click="editValue = false"
											:disabled="!editPermission"
										>
											<v-icon>mdi-close</v-icon>
										</v-btn>
										<v-btn
											icon
											color="warning"
											@click="sendEditValue"
											:disabled="!editPermission"
										>
											<v-icon>mdi-check</v-icon>
										</v-btn>
									</template>
								</v-text-field>
							</div>
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover" v-else>
								<div class="d-flex justify-space-between" style="width: 100%"><div style="width: 150px">Valor Visita Técnica</div><span>:</span> <b class="text-end" style="flex: 1;">${{conversation.project_info?.address_value | toThousandFilter}}</b></div>
								<div class="action-container">
									<v-btn
										icon
										color="warning"
										x-small
										@click="editValue = !editValue"
										:disabled="!editPermission"
									>
										<v-icon>mdi-pencil-outline</v-icon>
									</v-btn>
								</div>
							</div>
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
								<div class="d-flex justify-space-between" style="width: 100%"><div style="width: 150px">Tipo de Proyecto</div><span>:</span> <b class="text-end" style="flex: 1;">{{conversation.project_info?.project_type.name}}</b></div>
								<v-menu offset-y z-index="9999" content-class="menu-bg-white" style="z-index: 9999; background-color: white;" v-if="$checkPermissions(['edit_crm'])">
									<template v-slot:activator="{ on, attrs }">
										<div class="action-container">
											<v-btn
												icon
												color="warning"
												x-small
												v-bind="attrs"
												v-on="on"
											>
												<v-icon dark small>mdi-swap-horizontal-bold</v-icon>
											</v-btn>
										</div>
									</template>
									<v-list dense class="py-0" max-height="200" style="z-index: 9999;background-color: white;">
										<v-list-item dense class="mb-1" style="min-height: 25px; background: #959595;">
											<p class="caption mb-0 font-weight-bold white--text">Cambiar Tipo de Proyecto</p>
										</v-list-item>
										<v-list-item-group color="#524D4D">
											<v-list-item
												v-for="(project_type, index) in $store.state.companies.companiesProjectTypes"
												:key="index"
												dense
												@click="changeProjectType(project_type.id)"
											>
												<v-list-item-title dense>
													{{ project_type.name }}
												</v-list-item-title>
											</v-list-item>
										</v-list-item-group>
									</v-list>
								</v-menu>
							</div>
							<v-divider class="my-3"></v-divider>
							<v-select
								v-if="$checkPermissions(['schedule_assignment'])"
								v-model="scheduledUserId"
								:items="$store.state.users.usersList"
								:loading="loadingUsers"
								item-text="profile.name"
								item-value="id"
								label="Usuario encargado"
								outlined
								dense
								required
								hide-details
								:rules="requiredRule"
								@change="changeUserAssignation"
							>
								<template v-slot:item="data">
									<v-list-item-content>
										<v-list-item-title>{{data.item.profile.name}} - <span class="light-blue-text">[{{data.item.email}}]</span></v-list-item-title>
									</v-list-item-content>
								</template>
								<template #append-outer>
									<v-btn
										class="my-0"
										icon
										@click="addUser"
									>
										<v-icon>mdi-account-plus</v-icon>
									</v-btn>
								</template>
							</v-select>
							<p v-else class="d-flex justify-space-between mb-1">Usuario Encargado: <b>{{scheduledUserName}}</b></p>
							<v-divider class="my-3"></v-divider>
							<p class="d-flex justify-space-between align-center mb-1">
								Estado Visita Técnica:
								<v-chip
									v-if="conversation.schedule"
									class="mt-1"
									:color="conversation.schedule.status == 1 ? 'orange':'green'"
									label
									:dark="conversation.schedule.status == 2"
									:outlined="conversation.schedule.status == 1"
									small
								>
									<v-icon small class="mr-2">{{ conversation.schedule.status == 1 ? 'mdi-alert':'mdi-check' }}</v-icon>
									{{vtStatusText}}
								</v-chip>
								<v-chip
									v-else
									class="mt-1"
									color="danger"
									label
									dark
									outlined
									small
								>
									<v-icon small class="mr-2">mdi-close</v-icon>
									No se generó agenda
								</v-chip>
							</p>
							<p class="d-flex justify-space-between align-center mb-1"
								v-if="conversation.schedule && conversation.schedule.payments.length > 0">Fecha de Pago Visita Técnica: <b>{{ conversation.schedule.payments[0].payment_info.created_at | toDateTime }}</b></p>
							<v-divider class="my-2"></v-divider>
							<div class="d-flex justify-space-between align-center mb-2">
								<p class="font-weight-bold mb-0">Visitas Técnicas</p>
								<v-chip
									class="mt-1"
									label
									outlined
									small
									@click="startCloseAgenda"
									:disabled="!editPermission"
								>
									<v-icon small class="mr-2">mdi-calendar-alert-outline</v-icon>
									Agregar Cierre Visita
								</v-chip>
							</div>
							<div class="px-2" v-if="conversation.schedule.closure.length > 0">
								<div class="d-flex px-1">
									<p class="py-1 mb-0 d-flex flex-1 caption font-weight-bold w-100" style="width: 100%">
										Fecha de realización
									</p>
									<v-divider class="mx-1" vertical></v-divider>
									<p class="py-1 mb-0 d-flex flex-1 caption font-weight-bold w-100" style="width: 100%">
										Comentario
									</p>
								</div>
								<v-divider></v-divider>
								<div class="py-0 d-flex px-1 item-background-hover" v-for="(closure,index) in conversation.schedule.closure" :key="index">
									<p class="py-1 mb-0 d-flex flex-1 caption" style="width: 100%">
										<v-icon small class="mr-2">mdi-calendar</v-icon>
										{{closure.closed}}
									</p>
									<v-divider class="mx-1" vertical></v-divider>
									<p class="py-1 mb-0 d-flex flex-1 caption" style="width: 100%">
										{{closure.closure_report}}
									</p>
								</div>
							</div>
							<v-divider class="my-2"></v-divider>
							<p class="d-flex justify-space-between align-center mb-1">
								Estado Informe:
								<v-chip
									class="mt-1"
									:color="vtStatus.color"
									small
									label
									dark
									v-if="vtStatus"
								>
									<v-icon class="mr-1" x-small>mdi-email-outline</v-icon>
									{{vtStatus.text}}
								</v-chip>
							</p>
							<v-divider class="my-3"></v-divider>
							<GmapMap
								v-if="geoAddress"
								:center="geoAddress"
								:zoom="mapZoom"
								@zoom_changed="updateZoom"
								ref="vtMap"
								style="width: 100%; height: 300px"
							>
								<GmapMarker
									:position="geoAddress"
									@mousedown="startZoomIn"
									@mouseup="stopZoomIn"
									:icon="{url: require('@/assets/marker_schedule.png'), scaledSize: { width: 45, height: 47 }}"
								/>
							</GmapMap>
							<v-btn
								depressed
								color="#524D4D"
								:dark="!((sentEmailDiff != -1 && sentEmailDiff < 24) || !(conversation.schedule && conversation.schedule.status == 1)) && editPermission"
								block
								small
								class="mt-3"
								:disabled="((sentEmailDiff != -1 && sentEmailDiff < 24) || !(conversation.schedule && conversation.schedule.status == 1)) && editPermission"
								:loading="loading"
								@click="resendPaymentRequest()"
							>
								<v-icon small>mdi-email-outline</v-icon>
								<p class="mb-0 ml-2 caption">Reenviar Solicitud de Pago</p>
							</v-btn>
							<v-btn
								depressed
								color="#524D4D"
								:dark="conversation.schedule && conversation.schedule.status == 1 && editPermission"
								block
								small
								class="mt-3"
								:loading="loading"
								:disabled="!(conversation.schedule && conversation.schedule.status == 1) || !editPermission"
								@click="confirmScheduleManual()"
							>
								<v-icon small>mdi-email-outline</v-icon>
								<p class="mb-0 ml-2 caption">Confirmar Visita Técnica (Transferencia)</p>
							</v-btn>
							<v-divider class="my-4"></v-divider>
							<p class="font-weight-bold body-2 mb-0">Pagos Realizados</p>
							<div class="py-0 px-1 d-flex align-center justify-space-between" v-for="(payment, index) in conversation.schedule.payments" :key="'p-' + index">
								<div class="d-flex flex-column mx-1 mt-2" style="flex: 1 1 auto;">
									<p class="caption mb-1 justify-space-between d-flex">Fecha de pago: <b>{{ payment.payment_info.created_at | toDateTime }}</b></p>
									<p class="caption mb-1 justify-space-between d-flex">Concepto: <b>{{payment.concept}}</b></p>
									<p class="caption mb-1 justify-space-between d-flex">Forma de Pago: <b>{{payment.payment_info.method}}</b></p>
									<p class="caption mb-1 justify-space-between d-flex">Monto: <b>${{payment.payment_info.amount | toThousandFilter}}</b></p>
								</div>
							</div>
							<v-divider class="my-4"></v-divider>
							<p class="font-weight-bold body-2 mb-0">Correos enviados</p>
							<div class="py-0 px-1 d-flex align-center justify-space-between" v-for="(email, index) in conversation.schedule.request_email" :key="'m-' + index">
								<div>
									<v-icon class="mr-2" small>mdi-email-outline</v-icon>
									{{ email.created_at | toDateTime }}
								</div>
								<v-chip
									class="ma-2"
									color="#959595"
									small
									dark
								>
									Solicitud de Pago/Confirmación
								</v-chip>
							</div>
							<div class="py-0 px-1 d-flex align-center justify-space-between"
								v-if="conversation.schedule && conversation.schedule.payments.length > 0">
								<div>
									<v-icon class="mr-2" small>mdi-email-outline</v-icon>
									{{ conversation.schedule.payments[0].payment_info.created_at | toDateTime }}
								</div>
								<v-chip
									class="ma-2"
									color="success"
									small
									dark
								>
									Fecha de Pago Visita Técnica
								</v-chip>
							</div>
						</div>
						<div class="pa-2" v-else>
							No se agendó visita técnica
						</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="7">
				<v-expansion-panels class="mb-4" v-model="activePanel" multiple>
					<v-expansion-panel :disabled="!editPermission">
						<v-expansion-panel-header class="px-4 py-2" style="min-height: 0">
							<div>
								<p class="font-weight-bold body-2 mb-0" style="opacity: 0.7">Preparar Nuevo Informe</p>
							</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-divider class="my-1"></v-divider>
							<div class="pa-2" v-if="conversation.schedule">
								<v-text-field
									v-model="subject"
									label="Asunto"
									placeholder="Asunto"
									outlined
									required
									hide-details
									dense
									class="mb-2 w-100"
									:rules="requiredRule"
								></v-text-field>
								<vue2-tinymce-editor v-model="reportEditor"
									:options="{
										plugins: 'paste image imagetools',
										language: 'es',
										language_url: 'https://cdn.tiny.cloud/1/no-api-key/tinymce/5/langs/es.js',
										font_formats: 'Archivo Narrow=system-ui; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
										setup: setupTiny,
										automatic_uploads:true,
										images_upload_handler: uploadImageFromTiny,
										file_picker_callback: filePickerTiny
									}"></vue2-tinymce-editor>
									<input 
										type="file" 
										ref="fileInput" 
										accept="image/*" 
										style="display: none;"
									/>
								<v-divider class="my-4"></v-divider>
								<v-row>
									<v-col cols="6">
										<div class="d-flex align-center justify-space-between mb-2">
											<p class="mb-0 font-weight-bold caption grey--text">Plantillas</p>
											<v-btn
												depressed
												color="#524D4D"
												x-small
												outlined
												:loading="loading"
												class="ml-2"
												@click="$store.commit('vt/setNewReportTemplateModal', true)"
											>
												<v-icon small>mdi-file-document-plus-outline</v-icon>
												<p class="mb-0 ml-2 caption">Agregar</p>
											</v-btn>
										</div>
										<div class="d-flex flex-column justify-content align-center">
												<!-- <v-autocomplete
													v-model="reportTemplate"
													:items="$store.state.vt.vtReportTemplates"
													:loading="$store.state.vt.loadingVtReportTemplates"
													outlined
													dense
													hide-details
													item-text="name"
													item-value="id"
													return-object
													label="Seleccione Plantilla"
													@change="onChangeReportTemplate"
												></v-autocomplete> -->
												<v-menu
													ref="menu"
													v-model="menuTemplates"
													transition="scale-transition"
													offset-y
													left
													nudge-top="-10"
													eager
												>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															depressed
															color="#524D4D"
															outlined
															dark
															block
															v-bind="attrs"
															v-on="on"
														>
															<p class="mb-0 mr-2">Seleccionar Plantilla</p>
															<v-icon>mdi-chevron-down</v-icon>
														</v-btn>
													</template>
													<div class="white d-flex flex-column">
														<div>
															<v-list class="template-list" dense>
																<v-list-item
																	class="template-list-item"
																	v-for="(template, i) in $store.state.vt.vtReportTemplates" :key="'t-' + i"
																	@click="onChangeReportTemplate(template)">
																	<v-list-item-content>
																		<v-list-item-title>{{template.name}}</v-list-item-title>
																	</v-list-item-content>
																	<v-list-item-action>
																		<div class="d-flex align-center">
																			<v-tooltip bottom>
																				<template v-slot:activator="{ on, attrs }">
																					<v-btn
																						fab
																						dark
																						x-small
																						icon
																						color="warning"
																						@click="editReportTemplate(template)"
																						v-bind="attrs"
																						v-on="on"
																					>
																						<v-icon small dark>mdi-pencil-outline</v-icon>
																					</v-btn>
																				</template>
																				<span>Editar</span>
																			</v-tooltip>
																			<v-tooltip bottom>
																				<template v-slot:activator="{ on, attrs }">
																					<v-btn
																						fab
																						dark
																						x-small
																						icon
																						color="#524D4D"
																						@click="editReportTemplate(template, true)"
																						v-bind="attrs"
																						v-on="on"
																					>
																						<v-icon small dark>mdi-content-duplicate</v-icon>
																					</v-btn>
																				</template>
																				<span>Duplicar</span>
																			</v-tooltip>
																			<v-tooltip bottom>
																				<template v-slot:activator="{ on, attrs }">
																					<v-btn
																						fab
																						dark
																						x-small
																						icon
																						color="error"
																						@click="deleteVTReportTemplates(template.id)"
																						v-bind="attrs"
																						v-on="on"
																					>
																						<v-icon small dark>mdi-delete-outline</v-icon>
																					</v-btn>
																				</template>
																				<span>Eliminar</span>
																			</v-tooltip>
																		</div>
																	</v-list-item-action>
																</v-list-item>
															</v-list>
														</div>
														<!-- <v-btn
															depressed
															color="#524D4D"
															text
															small
															:loading="loading"
															@click="$store.commit('vt/setNewReportTemplateModal', true)"
														>
															<v-icon>mdi-plus</v-icon>
															<p class="mb-0 ml-2">Nueva Plantilla</p>
														</v-btn>
														<v-btn
															depressed
															color="#524D4D"
															:dark="!!reportTemplate"
															text
															:loading="loading"
															small
															:disabled="!reportTemplate"
															@click="editReportTemplate()"
														>
															<v-icon>mdi-pencil</v-icon>
															<p class="mb-0 ml-2">Editar Selección</p>
														</v-btn> -->
													</div>
												</v-menu>
										</div>
										<!-- <v-divider class="my-2"></v-divider>
										<v-btn
											depressed
											color="#524D4D"
											x-small
											outlined
											:loading="loading"
											class="ml-2"
											@click="$store.commit('vt/setNewReportTemplateModal', true)"
										>
											<v-icon small>mdi-send</v-icon>
											<p class="mb-0 ml-2 caption">Nueva Plantilla</p>
										</v-btn> -->
									</v-col>
									<v-col cols="6">
									</v-col>
								</v-row>
								<v-divider class="my-3"></v-divider>
								<v-row>
									<v-col cols="6">
										<div class="d-flex align-center">
											<p class="mb-0 font-weight-bold caption grey--text">Enviar con copia (Ingresa correos separados por coma)</p>
										</div>
										<div class="d-flex flex-column justify-content align-center">
												<div style="width: 100%;">
													<v-text-field
														v-model="cc_report"
														label="Enviar con copia"
														placeholder="Enviar con copia"
														outlined
														required
														hide-details
														dense
														class="mb-2 w-100"
													></v-text-field>
												</div>
												<!-- <v-btn
													depressed
													color="#524D4D"
													:dark="!!reportTemplate"
													icon
													:loading="loading"
													class="ml-2"
													style="height: 40px"
													:disabled="!reportTemplate"
													@click="editReportTemplate()"
												>
													<v-icon>mdi-pencil-box-outline</v-icon>
												</v-btn> -->
										</div>
										<!-- <v-divider class="my-2"></v-divider>
										<v-btn
											depressed
											color="#524D4D"
											x-small
											outlined
											:loading="loading"
											class="ml-2"
											@click="$store.commit('vt/setNewReportTemplateModal', true)"
										>
											<v-icon small>mdi-send</v-icon>
											<p class="mb-0 ml-2 caption">Nueva Plantilla</p>
										</v-btn> -->
									</v-col>
									<v-col cols="6">
										<p class="mb-0 font-weight-bold caption grey--text">Confirmar envío</p>
										<v-btn
											depressed
											color="#524D4D"
											dark
											small
											block
											:loading="loading"
											@click="sendScheduleReport()"
										>
											<v-icon small>mdi-email-outline</v-icon>
											<p class="mb-0 ml-2 caption">Confirmar y Enviar Informe</p>
										</v-btn>
										<v-btn
											depressed
											color="#524D4D"
											dark
											small
											block
											outlined
											:loading="loading"
											@click="cleanReportEditor"
											class="mt-2"
										>
											<v-icon small>mdi-cancel</v-icon>
											<p class="mb-0 ml-2 caption">Limpiar Todo</p>
										</v-btn>
									</v-col>
								</v-row>
							</div>
						</v-expansion-panel-content>
					</v-expansion-panel>
					<v-expansion-panel>
						<v-expansion-panel-header class="px-4 py-2" style="min-height: 0">
							<div>
								<p class="font-weight-bold body-2 mb-0" style="opacity: 0.7">Informe</p>
							</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<div class="d-flex justify-space-between">
								<p class="caption mb-0">Contenido Informe</p>
								<div class="d-flex caption align-center" v-if="conversation.schedule && conversation.schedule.report">
									<v-icon small class="mr-2">mdi-calendar</v-icon> {{conversation.schedule.report.created_at | toDateTime}}
								</div>
							</div>
							<v-divider class="my-2"></v-divider>
							<div
								v-if="conversation.schedule && conversation.schedule.report && conversation.schedule.report.report_html"
								v-html="conversation.schedule.report.report_html"></div>
						</v-expansion-panel-content>
					</v-expansion-panel>
					<v-expansion-panel>
						<v-expansion-panel-header class="px-4 py-2" style="min-height: 0">
							<div>
								<p class="font-weight-bold body-2 mb-0" style="opacity: 0.7">Detalle Conversación</p>
							</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<div class="pa-2">
								<div>
									<div class="messages-container" v-if="conversationMessages.length > 0">
										<div
											v-for="(message, index) in conversationMessages"
											:key="'msg-' + index"
											class="single-message" :class="message.role == 1 ? 'assistant':''"
											v-html="$options.filters.addTargetsToLinksMessages(message.message)">
										</div>
									</div>
								</div>
							</div>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
        <!-- <v-card>
          <v-card-text class="py-2">
						<div>
							<p class="font-weight-bold body-2 mb-0">Detalle Conversación</p>
						</div>
						<v-divider class="my-1"></v-divider>
						<div class="pa-2">
							<div>
								<div class="messages-container" v-if="conversationMessages.length > 0">
									<div
										v-for="(message, index) in conversationMessages"
										:key="index"
										class="single-message" :class="message.role == 1 ? 'assistant':''"
										v-html="$options.filters.addTargetsToLinksMessages(message.message)">
									</div>
								</div>
							</div>
						</div>
          </v-card-text>
        </v-card> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import { Vue2TinymceEditor } from "vue2-tinymce-editor"
import {sendScheduleEmail, editConversationUser} from '@/helpers/api/conversations'
import {editCrmValue, assignScheduleToUser, editCrmProjectDetail, editCrmProjectType} from '@/helpers/api/crm'
import {confirmSchedule, sendReportSchedule, updateScheduleAddress, updateUserSchedule} from '@/helpers/api/schedule'
import {deleteVTReportTemplate} from '@/helpers/api/vts'
import {fileUploadToReport} from '@/helpers/api/files'
import { gmapApi } from 'vue2-google-maps'

export default {
	name: 'Conversation',
  components: {
    Vue2TinymceEditor
  },
  data() {
    return {
			mapZoom: 7,
      zoomInterval: null,
      isZooming: false,
			itemsAddress: [],
			loadingAddress: false,
			search: '',
			autocompleteService: null,
			tab: null,
			items: ['Información', 'Conversación', 'Correos Enviados', 'Informes'],
			reportTemplate: null,
			loading: false,
			editValue: false,
			editProjectDetail: false,
			editScheduleTitle: false,
			editUser: false,
			editAddress: false,
			newValueCrm: null,
			newScheduleTitle: null,
			newProjectDetail: null,
			loadingEditUser: false,
			reportEditor: '',
			menuTemplates: false,
			cc_report: null,
			scheduledUserId: null,
			changingScheduleUser: false,
			subject: 'Informe Visita Técnica',
			report_title: 'Informe Visita Técnica Revisión Normativa',
			activePanel: 2,
			templateName: null,
			userData: {
				name: null,
				email: null,
				phone: null
			},
			cachingItems: false,
			newAddress: {address: null, coordinates: null},
			requiredRule: [
				v => !!v || 'Campo requerido'
			],
			phoneRule: [
				v => !!v || 'Campo requerido',
				v => /^569\d{8}$/.test(v) || 'Debe tener 11 dígitos y empezar con 569',
			],
    };
  },
  computed: {
		google: gmapApi,
		editPermission(){
			return this.$checkPermissions(['edit_crm']) || this.scheduledUserId == this.$store.state.user.userInfo.id
		},
		scheduledUserName(){
			const user = this.$store.state.users.usersList.find((user) => {
				return user.id == this.scheduledUserId
			})
			if(!user){ return null }
			return user.profile.name + ' ' + user.profile.lastname
		},
		loadingUsers(){
			return this.$store.state.users.loadingUserList || this.changingScheduleUser
		},
		vtStatusText(){
			if(this.conversation.schedule.closure.length > 0){
				return 'Realizada'
			}
			else if(this.conversation.schedule.status == 1){
				return 'Esperando confirmación/pago'
			}
			return 'Confirmada'
		},
    conversation() {
      return this.$store.state.crm.conversation
    },
    reportTemplates() {
      return this.$store.state.vt.vtReportTemplates
    },
		conversationMessages(){
      return this.$store.state.crm.conversation.messages
		},
		geoAddress(){
			if(this.conversation && this.conversation.project_info && this.conversation.project_info?.coordinates){
				return {lat: this.conversation.project_info?.coordinates.coordinates[1], lng: this.conversation.project_info?.coordinates.coordinates[0]}
			}
			return null
		},
		sentEmailDiff(){
			if(this.conversation.schedule && this.conversation.schedule.request_email.length > 0){
				const current = moment()
				const startTime = this.conversation.schedule.request_email[0].created_at
				var duration = moment.duration(current.diff(startTime))
				var hours = duration.asHours()
				return hours
			}
			else if(this.conversation.schedule.request_email.length == 0){
				return -1
			}
			return null
		},
		vtStatus(){
			if(this.conversation.schedule && this.conversation.schedule.status == 2 && this.conversation.schedule.request_email.length > 0){
				return {
					text: 'Informe Enviado',
					color: 'success'
				}
			}
			else if(this.conversation.schedule){
				return {
					text: 'Informe Pendiente',
					color: 'warning'
				}
			}
			return null
		}
  },
	watch: {
    conversation(newValue) {
			if(newValue && newValue.user){
				this.userData = {
					name: newValue.user.name,
					email: newValue.user.email,
					phone: newValue.user.phone
				}
				this.scheduledUserId = newValue.schedule?.user_schedule?.user_id
			}
    },
    search(newSearch) {
			this.cachingItems = false
      if (newSearch) {
        this.googleSearch(newSearch);
      } else {
        this.itemsAddress = []; // Limpiar los resultados si no hay búsqueda
      }
    },
		activePanel(newValue){
			if(newValue >= 0){
				this.$store.dispatch('vt/getVtReportTemplates')
			}
		},
		reportTemplates(newValue){
			if(newValue && this.reportTemplate){
				const reportSelected = newValue.find((report) => {
					return this.reportTemplate.id == report.id
				})
				if(reportSelected){
					this.onChangeReportTemplate(reportSelected)
				}
			}
		}
	},
	mounted(){
		try {
			this.$store.dispatch('users/getUserFromCompany')
			this.$store.dispatch('crm/getConversation', this.$route.params.id)
			this.$nextTick(() => {
				this.$gmapApiPromiseLazy().then(() => {
					this.autocompleteService = new this.google.maps.places.AutocompleteService()
				})
			});
		} catch (error) {
			console.log('Error: ', error)
		}
	},
	beforeDestroy(){
		this.$store.commit('crm/setConversation', null)
	},
  methods: {
		async changeProjectType(project_type_id){
			await editCrmProjectType({conversation_id: this.conversation.id, project_type_id})
			this.$store.dispatch('crm/getConversation', this.$route.params.id)
		},
		startEditProjectDetail(){
			this.editProjectDetail = !this.editProjectDetail
			this.newProjectDetail = this.conversation.project_info?.project_detail
		},
		startEditTitle(){
			this.editScheduleTitle = !this.editScheduleTitle
			this.newScheduleTitle = this.conversation.schedule.user_schedule?.title
		},
		async sendEditProjectDetail(){
			await editCrmProjectDetail({conversation_id: this.conversation.id, project_detail: this.newProjectDetail})
			this.editProjectDetail = false
			this.$store.dispatch('crm/getConversation', this.$route.params.id)
		},
		async sendEditScheduleTitle(){
			await updateUserSchedule({schedule_id: this.conversation.schedule.user_schedule.id, title: this.newScheduleTitle})
			this.editScheduleTitle = false
			this.$store.dispatch('crm/getConversation', this.$route.params.id)
		},
		startEditAddress(){
			this.cachingItems = true
			this.editAddress = !this.editAddress
			let address = this.conversation.project_info?.project_address ?? ''
			if(this.conversation.project_info?.project_district && this.conversation.project_info?.project_district.length > 1){
				address += ' ' + this.conversation.project_info?.project_district
			}
			this.itemsAddress = [{text: address.toUpperCase(), value: address.toUpperCase()}]
			this.newAddress.address = address.toUpperCase()
		},
    addUser() {
			this.$store.commit('users/setNewUserModalEditing', null)
			this.$store.commit('users/setNewUserModal', true)
    },
		isNumberKey(event) {
			const charCode = event.which ? event.which : event.keyCode;
			if (charCode < 48 || charCode > 57) {
				event.preventDefault();
			}
		},
		async changeUserAssignation(new_user_id){
			this.changingScheduleUser = true
			await assignScheduleToUser({new_user_id, schedule_id: this.conversation.schedule.user_schedule.id})
			this.$store.dispatch('crm/getConversation', this.$route.params.id)
			this.changingScheduleUser = false
		},
		async sendEditValue(){
			await editCrmValue({conversation_id: this.$route.params.id, new_value: this.newValueCrm})
			this.editValue = false
			this.$store.dispatch('crm/getConversation', this.$route.params.id)
		},
		editCrmSchedule(){
			let address = null
			let coordinates = null
			if(this.conversation.project_info && this.conversation.project_info?.project_address){
				address = this.conversation.project_info?.project_address
				coordinates = this.conversation.project_info?.coordinates
			}
			this.$store.commit('schedule/setNewScheduleEditing', {
				title: 'Visita Técnica',
				user_name: this.userData.name,
				user_email: this.userData.email,
				user_phone: this.userData.phone,
				crm_id: this.$route.params.id,
				address: {address, coordinates},
				to_crm: true
			})
			this.$store.commit('schedule/setNewScheduleModal', true)
		},
    startZoomIn() {
      this.isZooming = true;
      this.zoomIn();
    },
    stopZoomIn() {
      this.isZooming = false;
      if (this.zoomInterval) {
        clearInterval(this.zoomInterval);
        this.zoomInterval = null;
      }
    },
    zoomIn() {
      if (this.zoomInterval) {
        clearInterval(this.zoomInterval);
      }

      this.zoomInterval = setInterval(() => {
        if (this.isZooming && this.mapZoom < 20) {
          this.mapZoom += 1;
					
					const map = this.$refs.vtMap.$mapObject;
					if (map) {
						map.setCenter(this.geoAddress); // El mapa se vuelve a centrar en el mismo punto
					}
        } else {
          clearInterval(this.zoomInterval);
          this.zoomInterval = null;
        }
      }, 100);
    },
    updateZoom(zoom) {
      this.mapZoom = zoom;
    },
    googleSearch(query) {
      const options = {
        input: query,
				componentRestrictions: { country: 'CL' }
      };
      this.autocompleteService.getPlacePredictions(options, (predictions, status) => {
        if (status === this.google.maps.places.PlacesServiceStatus.OK) {
          this.itemsAddress = predictions.map(prediction => ({
            text: prediction.description,
            value: prediction 
          }));
        } else {
          console.error('Error fetching predictions: ', status);
          this.itemsAddress = []; // Limpiar los resultados en caso de error
        }
      });
    },
		async getPlaceDetails(placeId) {
			if (typeof this.google !== 'undefined' && this.google.maps) {
				const service = new this.google.maps.places.PlacesService(document.createElement('div'));
				
				service.getDetails({ placeId }, (place, status) => {
					if (status === this.google.maps.places.PlacesServiceStatus.OK) {
						const { geometry } = place;
						this.newAddress.coordinates = {
							lat: geometry.location.lat(),
							lng: geometry.location.lng()
						}
						this.updateAddress(this.newAddress)
					}
				});
			} else {
				console.error('Google Maps API no está cargada');
			}
		},
    change(value) {
			console.log('value', value)
			this.getPlaceDetails(value.value.place_id)
      this.newAddress.address = value.text;
    },
		async updateAddress(newAddress){
			this.editAddress = false
			const data = {
				...{schedule_id: this.conversation.schedule.id},
				...newAddress
			}
			await updateScheduleAddress(data)
			this.$store.dispatch('crm/getConversation', this.$route.params.id)
		},
		uploadImageFromTiny(file, succes, failure){
			const formData = new FormData();
			formData.append('file', file.blob());

			fileUploadToReport(formData)
				.then((uploaded_file) => {
					if (uploaded_file.code === 200) {
						console.log(uploaded_file.url);
						succes(uploaded_file.url);
					} else {
						failure(new Error(`Error: ${uploaded_file.code}`));
					}
				})
				.catch((error) => {
					failure(error);
				});
		},
		filePickerTiny(callback) {
			const input = this.$refs.fileInput;
			input.onchange = async (event) => {
				const file = event.target.files[0];
				const formData = new FormData();
				formData.append('file', file);
				try {
					const uploaded_file = await fileUploadToReport(formData);
					if (uploaded_file.code === 200) {
						const url = uploaded_file.url;
						callback(url, { alt: file.name });
					} else {
						console.error('Error al subir la imagen:', uploaded_file.message);
					}
				} catch (error) {
					console.error('Error en la subida de la imagen:', error);
				}
			};

			// Simular un clic en el input para abrir el selector de archivos
			input.click();
		},
		setupTiny(editor){
			editor.on('paste', (e) => {
				const clipboard = e.clipboardData || e.originalEvent.clipboardData;
				console.log('clipboard', clipboard);
				
				if (clipboard && clipboard.items) {
					let pastedContent = '';
					let imageCounter = 0;

					const handleItem = (item) => {
							return new Promise((resolve) => {
									if (item.type.indexOf('image') !== -1) {
											// Procesar imágenes como archivos
											const file = item.getAsFile();
											const formData = new FormData();
											formData.append('file', file);

											fileUploadToReport(formData)
													.then((uploaded_file) => {
															if (uploaded_file.code == 200) {
																	const url = uploaded_file.url;
																	pastedContent += `<img src="${url}" />`;
															}
															resolve();
													});
									} else if (item.type === 'text/html') {
											// Procesar HTML (posibles imágenes en base64)
											item.getAsString((htmlContent) => {
													const parser = new DOMParser();
													const doc = parser.parseFromString(htmlContent, 'text/html');
													const images = doc.querySelectorAll('img');

													if (images.length > 0) {
														// Si hay imágenes en base64, subirlas al servidor
														images.forEach((img, index) => {
															if (img.src.startsWith('data:image')) {
																const base64Data = img.src.split(',')[1];
																const contentType = img.src.split(';')[0].split(':')[1];

																const byteCharacters = atob(base64Data);
																const byteNumbers = new Array(byteCharacters.length);
																for (let i = 0; i < byteCharacters.length; i++) {
																	byteNumbers[i] = byteCharacters.charCodeAt(i);
																}

																const byteArray = new Uint8Array(byteNumbers);
																const blob = new Blob([byteArray], { type: contentType });
																const file = new File([blob], `image${imageCounter}.png`, { type: contentType });

																const formData = new FormData();
																formData.append('file', file);
																imageCounter++;

																// Subir imagen al servidor
																fileUploadToReport(formData)
																	.then((uploaded_file) => {
																		if (uploaded_file.code == 200) {
																			img.src = uploaded_file.url;
																		}
																		if (index === images.length - 1) {
																			pastedContent += doc.body.innerHTML;
																			resolve();
																		}
																	});
															} else {
																// Si no es base64, insertar la imagen directamente
																pastedContent += doc.body.innerHTML;
																resolve();
															}
														});
													} else {
															// No hay imágenes, solo insertar el HTML pegado
															pastedContent += htmlContent;
															resolve();
													}
											});
									} else if (item.type === 'text/plain') {
											// Procesar texto sin formato
											item.getAsString((plainText) => {
													pastedContent += plainText;
													resolve();
											});
									} else {
											resolve();
									}
							});
					};

					const processItemsSequentially = async () => {
							for (let i = 0; i < clipboard.items.length; i++) {
									await handleItem(clipboard.items[i]);
							}
							// Una vez procesados todos los ítems, insertar el contenido en el editor
							editor.execCommand('mceInsertContent', false, pastedContent);
					};

					// Procesar los ítems de forma secuencial
					processItemsSequentially();
				}
			});
		},
		startCloseAgenda(){
			if(this.conversation.schedule){
				this.$store.commit('vt/setAgendaClosureModal', this.conversation.schedule.id)
			}
		},
		async editReportTemplate(reportTemplate, duplcating = false){
			const rt = {...reportTemplate}
			if(duplcating){
				rt.name = rt.name + ' (Copia)'
				delete rt.id
			}
			this.$store.commit('vt/setNewReportTemplateEditing', rt)
      this.$store.commit('vt/setNewReportTemplateModal', true)
		},
		cleanReportEditor(){
			this.reportEditor = ''
		},
		onChangeReportTemplate(report_template){
			this.report_title = report_template.name
			this.reportEditor = report_template.content
		},
		async deleteVTReportTemplates(template_id){
			await deleteVTReportTemplate({template_id})
			this.$store.dispatch('vt/getVtReportTemplates')
		},
		async sendScheduleReport(){
			const recipients = this.cc_report ? this.cc_report.split(",").map(email => email.trim()):null
			const data = {
				schedule_id: this.conversation.schedule.id,
				report: this.reportEditor,
				recipients,
				subject: this.subject,
				report_title: this.report_title
			}
			await sendReportSchedule(data)
			this.$store.dispatch('crm/getConversation', this.$route.params.id)
		},
		async editUserData(){
			this.loadingEditUser = true
			const data = {...this.userData, ...{conversation_id: this.conversation.id}}
			await editConversationUser(data)
			this.$store.dispatch('crm/getConversation', this.$route.params.id)
			this.loadingEditUser = false
			this.editUser = false
		},
		async resendPaymentRequest(){
			if(!this.conversation.project_info?.address_value || this.conversation.project_info?.address_value == 0){
				const data = {
					message: 'Por favor asigna un valor a la visita técnica antes de enviar el correo.',
					title: 'Visita sin valor',
					created: new Date(),
					type: 'warning',
					icon: 'mdi-exclamation',
					color: 'orange'
				}
				this.$store.dispatch('general/addMessage', data)
				return null
			}
			this.loading = true
			const schedule_payment = await sendScheduleEmail({schedule_id: this.conversation.schedule.id})
			if(schedule_payment.code == 200){
				const data = {
					message: 'El correo con la solicitud de pago ha sido enviado al usuario.',
					title: 'Correo enviado correctamente',
					created: new Date(),
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3'
				}
				this.$store.dispatch('general/addMessage', data)
				this.$store.dispatch('crm/getConversation', this.$route.params.id)
			}
			else{
				const data = {
					message: 'Por favor intenta nuevamente.',
					title: 'Error al enviar el correo',
					created: new Date(),
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#F4516C',
				}
				this.$store.dispatch('general/addMessage', data)
			}
			this.loading = false
		},
		async confirmScheduleManual(){
			this.loading = true
			const schedule_payment = await confirmSchedule({schedule_id: this.conversation.schedule.id, payment_method: 'Transferencia'})
			if(schedule_payment.code == 200){
				const data = {
					message: 'Se ha confirmado la visita técnica.',
					title: 'Agenda Confirmada',
					created: new Date(),
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3'
				}
				this.$store.dispatch('general/addMessage', data)
				this.$store.dispatch('crm/getConversation', this.$route.params.id)
			}
			else{
				const data = {
					message: 'Por favor intenta nuevamente.',
					title: 'Error al confirmar',
					created: new Date(),
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#F4516C',
				}
				this.$store.dispatch('general/addMessage', data)
			}
			this.loading = false
		}
  },
};
</script>

<style scoped>
	.messages-container{
		display: flex;
		flex-direction: column;
		padding: 10px;
		flex: 1;
		border-radius: 10px;
		height: 400px;
		overflow-y: scroll;
	}
	.single-message{
		background-color: rgb(217, 239, 217);
		border: 1px solid rgb(197, 232, 197);
		padding: 4px 8px;
		max-width: 80%;
		border-radius: 10px;
		font-size: 14px;
		margin-bottom: 8px;
		text-transform: none;
	}
	.single-message.assistant{
		background-color: rgb(213, 211, 211);
		border: 1px solid rgb(193, 191, 191);
		align-self: flex-end;
	}
	.template-list-item:hover{
		background-color: rgba(0,0,0,0.2);
	}
	.menu-bg-white{background: white;}
	.item-background-hover:hover{
		background-color: rgba(0,0,0,0.1);
	}
	.action-container{
		width: 30px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
</style>
<style>
	.crm-conversation .single-message p{
		margin-bottom: 0;
	}
</style>