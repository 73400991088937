const sidebarStore = {
  namespaced: true,
  state: {
    sidebarStatus: true,
    menuSidebarStatus: true,
    sidebarMini: false,
    sidebarMenu: [
      {
        name: 'Panel de Control',
        routeName: 'Dashboard',
        icon: 'mdi-view-dashboard-outline',
        active: true
      },
      {
        name: 'Empresas',
				routeName: 'Companies',
        icon: 'mdi-domain',
        active: false,
				subMenu: [
					{
						name: 'Empresas',
						routeName: 'Companies',
						icon: 'mdi-domain',
						active: false
					},
					{
						name: 'Roles Empresa',
						routeName: 'Roles',
						icon: 'mdi-account-lock-open-outline',
						active: false,
						requiredPermissions: ['view_company_roles']
					},
					{
						name: 'Tipos de Proyecto',
						routeName: 'ProjectTypes',
						icon: 'mdi-text-box-outline',
						active: false,
						requiredPermissions: ['view_company_project_types']
					}
				]
      },
      {
        name: 'Usuarios',
        routeName: 'Users',
        icon: 'mdi-account-multiple-outline',
        active: false,
				requiredPermissions: ['view_users']
      },
      {
        name: 'Aceptación Políticas',
        routeName: 'Policies',
        icon: 'mdi-file-document-check-outline',
        active: false,
				requiredPermissions: ['super_admin_rights']
      },
      {
        name: 'CRM / Solicitudes',
        routeName: 'CRM',
        icon: 'mdi-contacts-outline',
        active: false,
				requiredPermissions: ['view_crm'],
				subMenu: [
					{
						name: 'Conceptos Conversación',
						routeName: 'CompanyConcepts',
						icon: 'mdi-information-outline',
						active: false,
						requiredPermissions: ['view_company_concepts']
					},
					{
						name: 'Mapa Visitas Técnicas',
						routeName: 'VTMap',
						icon: 'mdi-map',
						active: false,
						requiredPermissions: ['view_vt_maps']
					}
				]
      },
      // {
      //   name: 'Chats',
      //   routeName: 'Chat',
      //   icon: 'mdi-forum-outline',
      //   active: false
      // },
      {
        name: 'Clientes',
        routeName: 'Clients',
        icon: 'mdi-account-group-outline',
        active: false,
				requiredPermissions: ['view_clients']
      },
      {
        name: 'Mi Ficha',
        routeName: 'ClientDetail',
        icon: 'mdi-account-outline',
        active: false,
				requiredPermissions: ['client_capabilities']
      },
      {
        name: 'Gestión Antecedentes',
        routeName: 'PreProjects',
        icon: 'mdi-information-variant-circle-outline',
        active: false,
				requiredPermissions: ['view_preprojects']
      },
      {
        name: 'Agenda',
        routeName: 'Schedules',
        icon: 'mdi-calendar',
        active: false,
				requiredPermissions: ['schedule_access']
      },
    ]
  },
  mutations: {
    setSidebarStatus(state, status) {
      state.sidebarStatus = status
    },
    setMenuSidebarStatus(state, status) {
      state.menuSidebarStatus = status
    },
    toggleSidebarMini(state) {
      state.sidebarMini = !state.sidebarMini
    }
  }
}

export default sidebarStore
