import {endpoints} from './endpoints'
import request from '../request'

export function createPreProject (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createPreProject,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getCompanyPreProjects (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCompanyPreProjects,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getPreProject (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getPreProject,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function addFileToPreProject (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.addFileToPreProject,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function removeFileFromPreProject (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.removeFileFromPreProject,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function setBudgetsPartCategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.setBudgetsPartCategory,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function removeBudgetPartCategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.removeBudgetPartCategory,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function setPreProjectBudgetsPart (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.setPreProjectBudgetsPart,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function addPreProjectBudgetsPart (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.addPreProjectBudgetsPart,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function removePreProjectBudgetsPart (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.removePreProjectBudgetsPart,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateDocumentRevision (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateDocumentRevision,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function sendReportPreproject (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.sendReportPreproject,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updatePreProjectInfo (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updatePreProjectInfo,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function setResponsible (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.setResponsible,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}