import { getCompanyPreProjects, getPreProject } from "@/helpers/api/preprojects"
import router from '@/router'
const listingColumns = localStorage.getItem('preproject_columns')

const PreProjectsStore = {
  namespaced: true,
  state: {
		loadingPreProjects: false,
    preProjects: [],
		preProject: null,
		loadingPreProject: false,
		newPreProjectModal: false,
		listingColumns: listingColumns ? JSON.parse(listingColumns):{
			id: true,
			client: false,
			address: false,
			rol: true,
			project_name: false,
			entity: true,
			unarchive: true,
			cbr: true,
			sii: true,
			responsible: true,
			observation: true,
			actions: true,
		}
  },
  mutations: {
    setPreProjects(state, preProjects) {
      state.preProjects = preProjects
    },
    setLoadingPreProjects(state, status) {
      state.loadingPreProjects = status
    },
    setPreProject(state, preProject) {
      state.preProject = preProject
    },
    setLoadingPreProject(state, status) {
      state.loadingPreProject = status
    },
    setNewPreProjectModal(state, status) {
      state.newPreProjectModal = status
    },
    setListingColumns(state, listingColumns) {
      state.listingColumns = listingColumns
			localStorage.setItem('preproject_columns', JSON.stringify(listingColumns))
    }
  },
  actions: {
    async getPreProjects({ commit }) {
      commit('setLoadingPreProjects', true)
      const data = {
        start: 0,
        limit: 20
      }
      const preProjects = await getCompanyPreProjects(data)
      if (preProjects.code === 200) {
        commit('setPreProjects', [])
				setTimeout(() => {
					commit('setPreProjects', preProjects.preprojects)
				}, 50);
      }
      commit('setLoadingPreProjects', false)
    },
    getPreProject({ commit }, { preproject_id = null, showLoader = true } = {}) {
			if(showLoader){
				commit('setLoadingPreProject', true)
				commit('setPreProject', null)
			}
      const data = {
        preproject_id
      }
      getPreProject(data)
				.then((preproject) => {
					if (preproject.code === 200) {
						commit('setPreProject', preproject.preproject)
					}
					commit('setLoadingPreProject', false)
				})
				.catch(() => {
					commit('setPreProject', null)
					commit('setLoadingPreProject', false)
					router.push({name: 'PreProjects'})
				})
    }
  }
}

export default PreProjectsStore
